import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@coin/shared/util-environments';
import { UpdateNotificationsService } from '@coin/shared/util-helpers';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class HeaderComponent {
  get currentEnvironment(): typeof environment {
    return environment;
  }

  constructor(
    private router: Router,
    protected updateNotifications: UpdateNotificationsService
  ) {}

  public gotoStartPage(): void {
    this.router.navigate(['/']);
  }
}
