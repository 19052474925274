import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import autosize from 'autosize';
import { CoinUser, Comment, LetterComment } from '@coin/shared/util-models';

@Component({
  selector: 'coin-siemens-energy-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  standalone: false
})
export class CommentComponent implements OnInit, AfterViewInit {
  @HostBinding('class.my-comment') myComment = false;
  @ViewChild('textarea') textarea: ElementRef;
  @Input() comment: LetterComment | Comment;
  @Input() user: CoinUser;

  ngOnInit(): void {
    this.myComment = this.user?.id === this.comment?.author?.id;
  }

  ngAfterViewInit(): void {
    autosize?.(this.textarea?.nativeElement);
  }
}
