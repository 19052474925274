import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { ClickedOutsideDirective, TemplateWithValueDirective } from '@coin/shared/util-directives';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { TranslateModule } from '@ngx-translate/core';
import { animationFrames, filter, first, switchMap } from 'rxjs';
import { V2TextInputComponent } from '../text-input/v2-text-input.component';

// TODO: needs Result open animation
@Component({
  selector: 'coin-v2-search-box',
  templateUrl: './v2-search-box.component.html',
  styleUrls: ['./v2-search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatIconModule, FormsModule, TranslateModule, V2TextInputComponent, OverlayModule, ReactiveFormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2SearchBoxComponent),
      multi: true
    }
  ],
  hostDirectives: [
    {
      directive: ClickedOutsideDirective,
      outputs: ['clickedOutside']
    }
  ]
})
export class V2SearchBoxComponent<T = unknown> extends DefaultControlValueAccessor<T> implements OnInit, AfterViewInit {
  @Input() placeholder: string;
  @Input() label: string;
  @Input() icon = 'search';
  @Input() loading: boolean;
  @Input() noResultsTextKey = 'general.no-results-found';
  @Output() search = new EventEmitter<string>();
  /** Emits selected items as events instead of storing them as a form control value. */
  @Output() formlessSelect = new EventEmitter<T>();
  @ContentChildren(TemplateWithValueDirective) results: QueryList<TemplateWithValueDirective<T>>;

  @ViewChild(V2TextInputComponent) searchInputElement: V2TextInputComponent;
  @ViewChild(CdkConnectedOverlay) cdkOverlay: CdkConnectedOverlay;

  protected isSearchResultVisible = signal<boolean>(false);
  protected searchControl = new FormControl('');

  @HostListener('focusin')
  public onFocusEnter(): void {
    if (!this.isSearchResultVisible()) {
      this.isSearchResultVisible.set(true);
    }
  }

  @HostListener('clickedOutside')
  public handleClickedOutside() {
    this.isSearchResultVisible.set(false);
  }

  @HostListener('document:keydown.escape')
  public onEscape(): void {
    if (this.isSearchResultVisible()) {
      this.isSearchResultVisible.set(false);
      this.searchInputElement.blur();
    }
  }

  constructor(private destroy: DestroyRef) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.formlessSelect.observed && this.ngControl) {
      throw new Error('V2 Search Box: formlessSelect cannot be used together with formControl or ngModel');
    }
  }

  public ngAfterViewInit(): void {
    this.results.changes
      .pipe(
        switchMap(() => animationFrames().pipe(first())),
        takeUntilDestroyed(this.destroy)
      )
      .subscribe(() => {
        this.cd.markForCheck();
        this.cdkOverlay.overlayRef?.updatePosition();
      });

    this.searchControl.valueChanges.pipe(filter(Boolean), takeUntilDestroyed(this.destroy)).subscribe(value => this.search.emit(value));
  }

  protected selectResult(value: T): void {
    this.isSearchResultVisible.set(false);

    if (this.formlessSelect.observed) {
      this.formlessSelect.emit(value);
      this.value = null;
    } else {
      this.value = value;
    }
    this.resetSearch();
  }

  protected clearValue(): void {
    this.value = undefined;
    this.resetSearch();
  }

  private resetSearch(): void {
    this.searchControl.reset('', { emitEvent: false });
  }

  protected handleDetach(): void {
    if (this.value) {
      this.resetSearch();
    }
  }

  public override setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    isDisabled ? this.searchControl.disable({ emitEvent: false }) : this.searchControl.enable({ emitEvent: false });
  }
}
