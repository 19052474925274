import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TranslateModule } from '@ngx-translate/core';
import { LightweightRuleComponent } from '../lightweight-rule/lightweight-rule.component';
import { V2ButtonComponent } from '../../button/v2-button.component';
import { LightWeightCondition, LightweightRule, LightweightRuleEngineConfig, LightweightRuleSet } from '../v2-lightweight-rule-engine.types';
import { ConditionPickerComponent } from '../condition-picker/condition-picker.component';
import { emptyRule } from '../v2-lightweight-rule-engine.component';

@Component({
  selector: 'coin-lightweight-rule-set',
  templateUrl: './lightweight-rule-set.component.html',
  styleUrls: ['./lightweight-rule-set.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, LightweightRuleComponent, V2ButtonComponent, ConditionPickerComponent, TranslateModule]
})
export class LightweightRuleSetComponent {
  @Input({ required: true }) config: LightweightRuleEngineConfig;
  @Input({ required: true }) ruleSet: LightweightRuleSet;
  @Input() translateKeys = false;
  @HostBinding('class.readonly') @Input() disabled = false;
  @Output() delete = new EventEmitter<LightweightRuleSet>();
  @Output() changes = new EventEmitter<void>();
  @HostBinding('class.mat-elevation-z2') shadow = true;

  public addRule() {
    this.ruleSet.rules.push(emptyRule());
    this.changes.emit();
  }

  public deleteRule(rule: LightweightRule) {
    this.ruleSet.rules = this.ruleSet.rules.filter(existingRule => existingRule !== rule);
    this.changes.emit();
  }

  public setCondition(condition: LightWeightCondition) {
    this.ruleSet.condition = condition;
    this.changes.emit();
  }
}
