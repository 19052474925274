import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { retry, switchMap, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HealthCheckService } from '@coin/shared/data-access';

@Component({
  selector: 'coin-siemens-energy-no-backend-screen',
  templateUrl: './no-backend-screen.component.html',
  styleUrls: ['./no-backend-screen.component.scss'],
  standalone: false
})
export class NoBackendScreenComponent implements OnInit {
  @Input() noPing = false;
  @Input() hideTimeInfo = false;

  private check: Subscription;

  constructor(
    private healthCheckService: HealthCheckService,
    private router: Router,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.setHealthcheck();
  }

  private setHealthcheck(): void {
    if (this.noPing) {
      return;
    }

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
      if (event instanceof NavigationEnd && !event?.url?.includes('fallback')) {
        this.check.unsubscribe();
      }
    });

    this.check = interval(2000)
      .pipe(
        switchMap(() => this.healthCheckService.ping()),
        tap(() => {
          this.router.navigate(['']);
          this.check.unsubscribe();
        }),
        retry(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
