import { ChangeDetectorRef, computed, Directive, effect, input, Signal } from '@angular/core';
import { combineLatest, filter, Observable, of } from 'rxjs';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { switchMapWithLoadingState, TrackedResult } from '@coin/shared/util-helpers';
import { startWith } from 'rxjs/operators';
import { V2DropdownComponent } from './v2-dropdown.component';

/** Adds a built-in search functionality to `coin-v2-dropdown` */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'coin-v2-dropdown[dataSource]',
  standalone: true,
  exportAs: 'dropdownSearch'
})
export class DropdownSearchDirective<T> {
  /** Either a search function or a fixed array of values */
  dataSource = input.required<T[] | ((s: string) => Observable<T[]>)>();

  constructor(
    private dropdown: V2DropdownComponent<T>,
    private cd: ChangeDetectorRef
  ) {
    effect(() => {
      const dataSource = this.dataSource();
      if (Array.isArray(dataSource)) {
        this.dropdown.searchDebounce = 0;
        this.cd.markForCheck();
      }
    });
    effect(() => {
      const { loading } = this.searchStatus();
      this.dropdown.loading = loading;
      this.cd.markForCheck();
    });
  }

  public results = computed(() => {
    return this.searchStatus().latestValue;
  });

  private searchStatus: Signal<TrackedResult<T[]>> = toSignal(
    combineLatest([this.dropdown.search.pipe(startWith('')), toObservable(this.dataSource)]).pipe(
      filter(([, dataSource]) => !!dataSource),
      switchMapWithLoadingState(([search, dataSource]) => {
        if (typeof dataSource === 'function') {
          return dataSource(search);
        }

        const lowerCaseSearch = search.toLowerCase();
        const filteredResults = dataSource.filter(value => this.searchObjectValuesDeep(value, lowerCaseSearch));
        return of(filteredResults);
      })
    )
  );

  /** For primitives, returns true if the converted string value contains the search text. <br>
   * For objects, returns true if any deeply nested primitive value contains the search text. */
  private searchObjectValuesDeep(obj: unknown, search: string): boolean {
    if (Array.isArray(obj)) {
      return obj.some(value => this.searchObjectValuesDeep(value, search));
    }
    if (typeof obj === 'object') {
      return Object.values(obj).some(value => this.searchObjectValuesDeep(value, search));
    }
    return obj.toString().toLowerCase().includes(search);
  }
}
