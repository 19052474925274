/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  COINSharedApplicationProtosResponsesStatusResponse,
  OrganisationManagementMasterDataCreateMasterDataModel,
  OrganisationManagementMasterDataMasterDataFieldValuesModel,
  OrganisationManagementMasterDataMasterDataModel,
  OrganisationManagementMasterDataUpdateMasterDataModel,
  OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel,
  OrganisationManagementOrganisationsMassChangeDeleteModel,
  OrganisationManagementOrganisationsMassChangeStartAndEndDateModel,
  OrganisationManagementOrganisationsValidateOrganisationsModel,
  OrganisationManagementOrganisationsValidationResultModel,
  OrganisationManagementPositionsPositionTypeTerminationSimulationModel,
  OrganisationManagementReportsLinemanagerDeviationsReportModel,
  OrganisationManagementReportsMonitoringReportModel,
  OrganisationManagementReportsReportConfigurationModel,
  OrganisationManagementReportsSeomStructureListReportModel,
  OrganisationManagementReportsSpanOfControlReportModel,
  OrganisationManagementRulesRuleModel,
  SharedEntitySearchScopeModel,
  SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel,
  SharedPageModelOfOrganisationManagementOrganisationsOrganisationModel,
  SharedPageModelOfSystemString,
  SharedRuleEngineRuleSetModel,
  TransactionStatusOfExportStatusMetadata
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface OrganisationManagementAdminServiceCreateMasterDataRequestParams {
  organisationManagementMasterDataCreateMasterDataModel?: OrganisationManagementMasterDataCreateMasterDataModel;
}

export interface OrganisationManagementAdminServiceCreateReportConfigurationReportsRequestParams {
  organisationManagementReportsReportConfigurationModel?: OrganisationManagementReportsReportConfigurationModel;
}

export interface OrganisationManagementAdminServiceCreateRulesRequestParams {
  organisationManagementRulesRuleModel?: OrganisationManagementRulesRuleModel;
}

export interface OrganisationManagementAdminServiceDeleteOrganisationsRequestParams {
  organisationManagementOrganisationsMassChangeDeleteModel?: OrganisationManagementOrganisationsMassChangeDeleteModel;
}

export interface OrganisationManagementAdminServiceDeleteRulesRequestParams {
  id: string;
}

export interface OrganisationManagementAdminServiceExportLineManagerDeviationsReportReportsRequestParams {
  organisationManagementReportsLinemanagerDeviationsReportModel?: OrganisationManagementReportsLinemanagerDeviationsReportModel;
}

export interface OrganisationManagementAdminServiceExportMonitoringReportReportsRequestParams {
  organisationManagementReportsMonitoringReportModel?: OrganisationManagementReportsMonitoringReportModel;
}

export interface OrganisationManagementAdminServiceExportSeomStructureListReportReportsRequestParams {
  organisationManagementReportsSeomStructureListReportModel?: OrganisationManagementReportsSeomStructureListReportModel;
}

export interface OrganisationManagementAdminServiceExportSpanOfControlReportReportsRequestParams {
  organisationManagementReportsSpanOfControlReportModel?: OrganisationManagementReportsSpanOfControlReportModel;
}

export interface OrganisationManagementAdminServiceExportToDataHubOrganisationsRequestParams {
  fullExport?: boolean;
}

export interface OrganisationManagementAdminServiceGetFieldValuesMasterDataRequestParams {
  commentSearch?: string;
  typeFilter?: string;
  valueSearch?: string;
  validFrom?: string;
  validTo?: string;
}

export interface OrganisationManagementAdminServiceGetFieldValuesRulesRequestParams {
  field: string;
  orgName?: string;
  orgCode?: string;
  parentOrgCode?: string;
  orgLevel?: number;
  orgUnit?: string;
  orgClass?: string;
  orgType?: string;
  validFrom?: string;
  validTo?: string;
  page?: number;
  size?: number;
}

export interface OrganisationManagementAdminServiceGetMasterDataRequestParams {
  commentSearch?: string;
  typeFilter?: string;
  valueSearch?: string;
  validFrom?: string;
  validTo?: string;
}

export interface OrganisationManagementAdminServiceGetReportConfigurationReportsRequestParams {
  reportType: string;
}

export interface OrganisationManagementAdminServiceGetRulesRequestParams {
  queryTitle?: string;
  queryType?: Array<string>;
  querySeverity?: Array<'Error' | 'Warning' | 'Information'>;
  queryValidationType?: Array<
    | 'OrgNameMatchOrgCodeAbbreviation'
    | 'ProhibitedOrgCodeAbbreviation'
    | 'ProhibitedOrgName'
    | 'EmptyHeadPosition'
    | 'OnlyOneHeadPosition'
    | 'MaximumOrgLevel'
    | 'InheritancePrinciple'
    | 'InvalidContractStatus'
    | 'InvalidWorkforceType'
    | 'OrgCodeAbbreviationMatchOrgName'
    | 'ProhibitedUseOfSubRegion'
    | 'NumberAsOrgCodeOnlyOnLowestLevel'
  >;
  queryConfiguration?: string;
  queryMessage?: string;
  queryIsActive?: boolean;
  queryValidFrom?: string;
  queryValidTo?: string;
  sortableOrderBy?: 'Asc' | 'Desc';
  sortableProperty?: string;
}

export interface OrganisationManagementAdminServiceQueryMasterDataActivityStreamRequestParams {
  page?: number;
  size?: number;
}

export interface OrganisationManagementAdminServiceQueryRuleActivityStreamRequestParams {
  page?: number;
  size?: number;
}

export interface OrganisationManagementAdminServiceSimulateRulesRequestParams {
  page?: number;
  size?: number;
  orderBy?: 'Asc' | 'Desc';
  property?: string;
  sharedRuleEngineRuleSetModel?: SharedRuleEngineRuleSetModel;
}

export interface OrganisationManagementAdminServiceSimulateTerminatePositionsRequestParams {
  /** Filter for given position title */
  title?: string;
  /** The date of termination */
  validOn?: string;
  validOnDate?: string;
}

export interface OrganisationManagementAdminServiceTerminateMasterDataRequestParams {
  id: string;
  validTo?: string;
  type?: string;
}

export interface OrganisationManagementAdminServiceUpdateMasterDataRequestParams {
  id: string;
  organisationManagementMasterDataUpdateMasterDataModel?: OrganisationManagementMasterDataUpdateMasterDataModel;
}

export interface OrganisationManagementAdminServiceUpdateOrganisationsRequestParams {
  organisationManagementOrganisationsMassChangeStartAndEndDateModel?: OrganisationManagementOrganisationsMassChangeStartAndEndDateModel;
}

export interface OrganisationManagementAdminServiceUpdateReportConfigurationReportsRequestParams {
  reportType: string;
  organisationManagementReportsReportConfigurationModel?: OrganisationManagementReportsReportConfigurationModel;
}

export interface OrganisationManagementAdminServiceUpdateRulesRequestParams {
  id: string;
  organisationManagementRulesRuleModel?: OrganisationManagementRulesRuleModel;
}

export interface OrganisationManagementAdminServiceValidateOrganisationsOrganisationsRequestParams {
  organisationManagementOrganisationsValidateOrganisationsModel?: OrganisationManagementOrganisationsValidateOrganisationsModel;
}

@Injectable({
  providedIn: 'root'
})
export class OrganisationManagementAdminService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createMasterData(
    requestParameters?: OrganisationManagementAdminServiceCreateMasterDataRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementMasterDataMasterDataModel>;
  public createMasterData(
    requestParameters?: OrganisationManagementAdminServiceCreateMasterDataRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementMasterDataMasterDataModel>>;
  public createMasterData(
    requestParameters?: OrganisationManagementAdminServiceCreateMasterDataRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementMasterDataMasterDataModel>>;
  public createMasterData(
    requestParameters?: OrganisationManagementAdminServiceCreateMasterDataRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementMasterDataCreateMasterDataModel = requestParameters?.organisationManagementMasterDataCreateMasterDataModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/master-data/create`;
    return this.httpClient.request<OrganisationManagementMasterDataMasterDataModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementMasterDataCreateMasterDataModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Create a new report
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceCreateReportConfigurationReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementReportsReportConfigurationModel>;
  public createReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceCreateReportConfigurationReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementReportsReportConfigurationModel>>;
  public createReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceCreateReportConfigurationReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementReportsReportConfigurationModel>>;
  public createReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceCreateReportConfigurationReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementReportsReportConfigurationModel = requestParameters?.organisationManagementReportsReportConfigurationModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/reports`;
    return this.httpClient.request<OrganisationManagementReportsReportConfigurationModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementReportsReportConfigurationModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Create Rule
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createRules(
    requestParameters?: OrganisationManagementAdminServiceCreateRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementRulesRuleModel>;
  public createRules(
    requestParameters?: OrganisationManagementAdminServiceCreateRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementRulesRuleModel>>;
  public createRules(
    requestParameters?: OrganisationManagementAdminServiceCreateRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementRulesRuleModel>>;
  public createRules(
    requestParameters?: OrganisationManagementAdminServiceCreateRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementRulesRuleModel = requestParameters?.organisationManagementRulesRuleModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/rules`;
    return this.httpClient.request<OrganisationManagementRulesRuleModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementRulesRuleModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Delete Organisations
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteOrganisations(
    requestParameters?: OrganisationManagementAdminServiceDeleteOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>;
  public deleteOrganisations(
    requestParameters?: OrganisationManagementAdminServiceDeleteOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>>;
  public deleteOrganisations(
    requestParameters?: OrganisationManagementAdminServiceDeleteOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>>;
  public deleteOrganisations(
    requestParameters?: OrganisationManagementAdminServiceDeleteOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementOrganisationsMassChangeDeleteModel = requestParameters?.organisationManagementOrganisationsMassChangeDeleteModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/organisations/delete`;
    return this.httpClient.request<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementOrganisationsMassChangeDeleteModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Delete Rule
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRules(
    requestParameters?: OrganisationManagementAdminServiceDeleteRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<COINSharedApplicationProtosResponsesStatusResponse>;
  public deleteRules(
    requestParameters?: OrganisationManagementAdminServiceDeleteRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<COINSharedApplicationProtosResponsesStatusResponse>>;
  public deleteRules(
    requestParameters?: OrganisationManagementAdminServiceDeleteRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<COINSharedApplicationProtosResponsesStatusResponse>>;
  public deleteRules(
    requestParameters?: OrganisationManagementAdminServiceDeleteRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteRules.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/rules/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<COINSharedApplicationProtosResponsesStatusResponse>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Export monitoring report
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportLineManagerDeviationsReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportLineManagerDeviationsReportReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public exportLineManagerDeviationsReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportLineManagerDeviationsReportReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public exportLineManagerDeviationsReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportLineManagerDeviationsReportReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public exportLineManagerDeviationsReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportLineManagerDeviationsReportReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementReportsLinemanagerDeviationsReportModel = requestParameters?.organisationManagementReportsLinemanagerDeviationsReportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/reports/linemanager-deviations`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementReportsLinemanagerDeviationsReportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Export monitoring report
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportMonitoringReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportMonitoringReportReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public exportMonitoringReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportMonitoringReportReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public exportMonitoringReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportMonitoringReportReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public exportMonitoringReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportMonitoringReportReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementReportsMonitoringReportModel = requestParameters?.organisationManagementReportsMonitoringReportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/reports/monitoring`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementReportsMonitoringReportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Export given seom structure list report
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportSeomStructureListReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSeomStructureListReportReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public exportSeomStructureListReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSeomStructureListReportReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public exportSeomStructureListReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSeomStructureListReportReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public exportSeomStructureListReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSeomStructureListReportReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementReportsSeomStructureListReportModel = requestParameters?.organisationManagementReportsSeomStructureListReportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/reports/seom-structure-list`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementReportsSeomStructureListReportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Export given span of control report
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportSpanOfControlReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSpanOfControlReportReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public exportSpanOfControlReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSpanOfControlReportReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public exportSpanOfControlReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSpanOfControlReportReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public exportSpanOfControlReportReports(
    requestParameters?: OrganisationManagementAdminServiceExportSpanOfControlReportReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementReportsSpanOfControlReportModel = requestParameters?.organisationManagementReportsSpanOfControlReportModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/reports/span-of-control`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementReportsSpanOfControlReportModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Export data to DataHub
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public exportToDataHubOrganisations(
    requestParameters?: OrganisationManagementAdminServiceExportToDataHubOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public exportToDataHubOrganisations(
    requestParameters?: OrganisationManagementAdminServiceExportToDataHubOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public exportToDataHubOrganisations(
    requestParameters?: OrganisationManagementAdminServiceExportToDataHubOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public exportToDataHubOrganisations(
    requestParameters?: OrganisationManagementAdminServiceExportToDataHubOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const fullExport = requestParameters?.fullExport;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (fullExport !== undefined && fullExport !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>fullExport, 'fullExport');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/organisations/export/datahub`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get master data field values
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldValuesMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesMasterDataRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementMasterDataMasterDataFieldValuesModel>;
  public getFieldValuesMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesMasterDataRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementMasterDataMasterDataFieldValuesModel>>;
  public getFieldValuesMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesMasterDataRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementMasterDataMasterDataFieldValuesModel>>;
  public getFieldValuesMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesMasterDataRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const commentSearch = requestParameters?.commentSearch;
    const typeFilter = requestParameters?.typeFilter;
    const valueSearch = requestParameters?.valueSearch;
    const validFrom = requestParameters?.validFrom;
    const validTo = requestParameters?.validTo;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (commentSearch !== undefined && commentSearch !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commentSearch, 'CommentSearch');
    }
    if (typeFilter !== undefined && typeFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>typeFilter, 'TypeFilter');
    }
    if (valueSearch !== undefined && valueSearch !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>valueSearch, 'ValueSearch');
    }
    if (validFrom !== undefined && validFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validFrom, 'ValidFrom');
    }
    if (validTo !== undefined && validTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validTo, 'ValidTo');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/master-data/field-values`;
    return this.httpClient.request<OrganisationManagementMasterDataMasterDataFieldValuesModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get field values
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getFieldValuesRules(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public getFieldValuesRules(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public getFieldValuesRules(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public getFieldValuesRules(
    requestParameters?: OrganisationManagementAdminServiceGetFieldValuesRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const field = requestParameters?.field;
    if (field === null || field === undefined) {
      throw new Error('Required parameter field was null or undefined when calling getFieldValuesRules.');
    }
    const orgName = requestParameters?.orgName;
    const orgCode = requestParameters?.orgCode;
    const parentOrgCode = requestParameters?.parentOrgCode;
    const orgLevel = requestParameters?.orgLevel;
    const orgUnit = requestParameters?.orgUnit;
    const orgClass = requestParameters?.orgClass;
    const orgType = requestParameters?.orgType;
    const validFrom = requestParameters?.validFrom;
    const validTo = requestParameters?.validTo;
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orgName !== undefined && orgName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgName, 'OrgName');
    }
    if (orgCode !== undefined && orgCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCode, 'OrgCode');
    }
    if (parentOrgCode !== undefined && parentOrgCode !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>parentOrgCode, 'ParentOrgCode');
    }
    if (orgLevel !== undefined && orgLevel !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgLevel, 'OrgLevel');
    }
    if (orgUnit !== undefined && orgUnit !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgUnit, 'OrgUnit');
    }
    if (orgClass !== undefined && orgClass !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgClass, 'OrgClass');
    }
    if (orgType !== undefined && orgType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgType, 'OrgType');
    }
    if (validFrom !== undefined && validFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validFrom, 'ValidFrom');
    }
    if (validTo !== undefined && validTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validTo, 'ValidTo');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/rules/field-values/${this.configuration.encodeParam({ name: 'field', value: field, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get master data as list
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetMasterDataRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementMasterDataMasterDataModel>>;
  public getMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetMasterDataRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementMasterDataMasterDataModel>>>;
  public getMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetMasterDataRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementMasterDataMasterDataModel>>>;
  public getMasterData(
    requestParameters?: OrganisationManagementAdminServiceGetMasterDataRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const commentSearch = requestParameters?.commentSearch;
    const typeFilter = requestParameters?.typeFilter;
    const valueSearch = requestParameters?.valueSearch;
    const validFrom = requestParameters?.validFrom;
    const validTo = requestParameters?.validTo;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (commentSearch !== undefined && commentSearch !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>commentSearch, 'CommentSearch');
    }
    if (typeFilter !== undefined && typeFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>typeFilter, 'TypeFilter');
    }
    if (valueSearch !== undefined && valueSearch !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>valueSearch, 'ValueSearch');
    }
    if (validFrom !== undefined && validFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validFrom, 'ValidFrom');
    }
    if (validTo !== undefined && validTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validTo, 'ValidTo');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/master-data`;
    return this.httpClient.request<Array<OrganisationManagementMasterDataMasterDataModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get Information of report
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceGetReportConfigurationReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementReportsReportConfigurationModel>;
  public getReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceGetReportConfigurationReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementReportsReportConfigurationModel>>;
  public getReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceGetReportConfigurationReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementReportsReportConfigurationModel>>;
  public getReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceGetReportConfigurationReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const reportType = requestParameters?.reportType;
    if (reportType === null || reportType === undefined) {
      throw new Error('Required parameter reportType was null or undefined when calling getReportConfigurationReports.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/reports/${this.configuration.encodeParam({ name: 'reportType', value: reportType, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<OrganisationManagementReportsReportConfigurationModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get all Rules
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRules(
    requestParameters?: OrganisationManagementAdminServiceGetRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementRulesRuleModel>>;
  public getRules(
    requestParameters?: OrganisationManagementAdminServiceGetRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementRulesRuleModel>>>;
  public getRules(
    requestParameters?: OrganisationManagementAdminServiceGetRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementRulesRuleModel>>>;
  public getRules(
    requestParameters?: OrganisationManagementAdminServiceGetRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const queryTitle = requestParameters?.queryTitle;
    const queryType = requestParameters?.queryType;
    const querySeverity = requestParameters?.querySeverity;
    const queryValidationType = requestParameters?.queryValidationType;
    const queryConfiguration = requestParameters?.queryConfiguration;
    const queryMessage = requestParameters?.queryMessage;
    const queryIsActive = requestParameters?.queryIsActive;
    const queryValidFrom = requestParameters?.queryValidFrom;
    const queryValidTo = requestParameters?.queryValidTo;
    const sortableOrderBy = requestParameters?.sortableOrderBy;
    const sortableProperty = requestParameters?.sortableProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryTitle !== undefined && queryTitle !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTitle, 'Query.Title');
    }
    if (queryType) {
      queryType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Type');
      });
    }
    if (querySeverity) {
      querySeverity.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Severity');
      });
    }
    if (queryValidationType) {
      queryValidationType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ValidationType');
      });
    }
    if (queryConfiguration !== undefined && queryConfiguration !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryConfiguration, 'Query.Configuration');
    }
    if (queryMessage !== undefined && queryMessage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryMessage, 'Query.Message');
    }
    if (queryIsActive !== undefined && queryIsActive !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsActive, 'Query.IsActive');
    }
    if (queryValidFrom !== undefined && queryValidFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryValidFrom, 'Query.ValidFrom');
    }
    if (queryValidTo !== undefined && queryValidTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryValidTo, 'Query.ValidTo');
    }
    if (sortableOrderBy !== undefined && sortableOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortableOrderBy, 'Sortable.OrderBy');
    }
    if (sortableProperty !== undefined && sortableProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortableProperty, 'Sortable.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/rules`;
    return this.httpClient.request<Array<OrganisationManagementRulesRuleModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get Search Scope for Rules
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getScopeActivityStream(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedEntitySearchScopeModel>;
  public getScopeActivityStream(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedEntitySearchScopeModel>>;
  public getScopeActivityStream(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedEntitySearchScopeModel>>;
  public getScopeActivityStream(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/activity-stream/scope`;
    return this.httpClient.request<SharedEntitySearchScopeModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get Search Scope for Rules
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getScopeRules(
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedEntitySearchScopeModel>;
  public getScopeRules(
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedEntitySearchScopeModel>>;
  public getScopeRules(
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedEntitySearchScopeModel>>;
  public getScopeRules(
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/rules/scope`;
    return this.httpClient.request<SharedEntitySearchScopeModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets the activity stream for master data.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryMasterDataActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryMasterDataActivityStreamRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>;
  public queryMasterDataActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryMasterDataActivityStreamRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>>;
  public queryMasterDataActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryMasterDataActivityStreamRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>>;
  public queryMasterDataActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryMasterDataActivityStreamRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/activity-stream/master-data`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets the activity stream for rules.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryRuleActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryRuleActivityStreamRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>;
  public queryRuleActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryRuleActivityStreamRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>>;
  public queryRuleActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryRuleActivityStreamRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>>;
  public queryRuleActivityStream(
    requestParameters?: OrganisationManagementAdminServiceQueryRuleActivityStreamRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const page = requestParameters?.page;
    const size = requestParameters?.size;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/activity-stream/rules`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementActivitiesOrganisationManagementActivityStreamModel>(
      'get',
      `${this.configuration.basePath}${localVarPath}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: <any>responseType_,
        withCredentials: this.configuration.withCredentials,
        headers: localVarHeaders,
        observe: observe,
        transferCache: localVarTransferCache,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Simulate a rule set
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public simulateRules(
    requestParameters?: OrganisationManagementAdminServiceSimulateRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfOrganisationManagementOrganisationsOrganisationModel>;
  public simulateRules(
    requestParameters?: OrganisationManagementAdminServiceSimulateRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfOrganisationManagementOrganisationsOrganisationModel>>;
  public simulateRules(
    requestParameters?: OrganisationManagementAdminServiceSimulateRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfOrganisationManagementOrganisationsOrganisationModel>>;
  public simulateRules(
    requestParameters?: OrganisationManagementAdminServiceSimulateRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const page = requestParameters?.page;
    const size = requestParameters?.size;
    const orderBy = requestParameters?.orderBy;
    const property = requestParameters?.property;
    const sharedRuleEngineRuleSetModel = requestParameters?.sharedRuleEngineRuleSetModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>page, 'Page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>size, 'Size');
    }
    if (orderBy !== undefined && orderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orderBy, 'OrderBy');
    }
    if (property !== undefined && property !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>property, 'Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/rules/simulate`;
    return this.httpClient.request<SharedPageModelOfOrganisationManagementOrganisationsOrganisationModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: sharedRuleEngineRuleSetModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Get a list of all Positions that collide with expected termination
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public simulateTerminatePositions(
    requestParameters?: OrganisationManagementAdminServiceSimulateTerminatePositionsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<Array<OrganisationManagementPositionsPositionTypeTerminationSimulationModel>>;
  public simulateTerminatePositions(
    requestParameters?: OrganisationManagementAdminServiceSimulateTerminatePositionsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<Array<OrganisationManagementPositionsPositionTypeTerminationSimulationModel>>>;
  public simulateTerminatePositions(
    requestParameters?: OrganisationManagementAdminServiceSimulateTerminatePositionsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<Array<OrganisationManagementPositionsPositionTypeTerminationSimulationModel>>>;
  public simulateTerminatePositions(
    requestParameters?: OrganisationManagementAdminServiceSimulateTerminatePositionsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const title = requestParameters?.title;
    const validOn = requestParameters?.validOn;
    const validOnDate = requestParameters?.validOnDate;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (title !== undefined && title !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>title, 'Title');
    }
    if (validOn !== undefined && validOn !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOn, 'ValidOn');
    }
    if (validOnDate !== undefined && validOnDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validOnDate, 'ValidOnDate');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/positions/types/simulate-terminate`;
    return this.httpClient.request<Array<OrganisationManagementPositionsPositionTypeTerminationSimulationModel>>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public terminateMasterData(
    requestParameters?: OrganisationManagementAdminServiceTerminateMasterDataRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementMasterDataMasterDataModel>;
  public terminateMasterData(
    requestParameters?: OrganisationManagementAdminServiceTerminateMasterDataRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementMasterDataMasterDataModel>>;
  public terminateMasterData(
    requestParameters?: OrganisationManagementAdminServiceTerminateMasterDataRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementMasterDataMasterDataModel>>;
  public terminateMasterData(
    requestParameters?: OrganisationManagementAdminServiceTerminateMasterDataRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling terminateMasterData.');
    }
    const validTo = requestParameters?.validTo;
    const type = requestParameters?.type;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (validTo !== undefined && validTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>validTo, 'ValidTo');
    }
    if (type !== undefined && type !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>type, 'Type');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/master-data/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/terminate`;
    return this.httpClient.request<OrganisationManagementMasterDataMasterDataModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateMasterData(
    requestParameters?: OrganisationManagementAdminServiceUpdateMasterDataRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementMasterDataMasterDataModel>;
  public updateMasterData(
    requestParameters?: OrganisationManagementAdminServiceUpdateMasterDataRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementMasterDataMasterDataModel>>;
  public updateMasterData(
    requestParameters?: OrganisationManagementAdminServiceUpdateMasterDataRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementMasterDataMasterDataModel>>;
  public updateMasterData(
    requestParameters?: OrganisationManagementAdminServiceUpdateMasterDataRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateMasterData.');
    }
    const organisationManagementMasterDataUpdateMasterDataModel = requestParameters?.organisationManagementMasterDataUpdateMasterDataModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/master-data/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/update`;
    return this.httpClient.request<OrganisationManagementMasterDataMasterDataModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementMasterDataUpdateMasterDataModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Change Start and EndDate (ValidFrom, ValidTo)
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateOrganisations(
    requestParameters?: OrganisationManagementAdminServiceUpdateOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>;
  public updateOrganisations(
    requestParameters?: OrganisationManagementAdminServiceUpdateOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>>;
  public updateOrganisations(
    requestParameters?: OrganisationManagementAdminServiceUpdateOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>>;
  public updateOrganisations(
    requestParameters?: OrganisationManagementAdminServiceUpdateOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementOrganisationsMassChangeStartAndEndDateModel = requestParameters?.organisationManagementOrganisationsMassChangeStartAndEndDateModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/organisations/publish`;
    return this.httpClient.request<OrganisationManagementOrganisationsCreateAndUpdateOrganisationsStatusModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementOrganisationsMassChangeStartAndEndDateModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Update a report
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceUpdateReportConfigurationReportsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementReportsReportConfigurationModel>;
  public updateReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceUpdateReportConfigurationReportsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementReportsReportConfigurationModel>>;
  public updateReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceUpdateReportConfigurationReportsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementReportsReportConfigurationModel>>;
  public updateReportConfigurationReports(
    requestParameters?: OrganisationManagementAdminServiceUpdateReportConfigurationReportsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const reportType = requestParameters?.reportType;
    if (reportType === null || reportType === undefined) {
      throw new Error('Required parameter reportType was null or undefined when calling updateReportConfigurationReports.');
    }
    const organisationManagementReportsReportConfigurationModel = requestParameters?.organisationManagementReportsReportConfigurationModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/reports/${this.configuration.encodeParam({ name: 'reportType', value: reportType, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: undefined })}`;
    return this.httpClient.request<OrganisationManagementReportsReportConfigurationModel>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementReportsReportConfigurationModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Update Rule
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRules(
    requestParameters?: OrganisationManagementAdminServiceUpdateRulesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementRulesRuleModel>;
  public updateRules(
    requestParameters?: OrganisationManagementAdminServiceUpdateRulesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementRulesRuleModel>>;
  public updateRules(
    requestParameters?: OrganisationManagementAdminServiceUpdateRulesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementRulesRuleModel>>;
  public updateRules(
    requestParameters?: OrganisationManagementAdminServiceUpdateRulesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateRules.');
    }
    const organisationManagementRulesRuleModel = requestParameters?.organisationManagementRulesRuleModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/rules/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<OrganisationManagementRulesRuleModel>('put', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementRulesRuleModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Validate Organisations
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public validateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementAdminServiceValidateOrganisationsOrganisationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<OrganisationManagementOrganisationsValidationResultModel>;
  public validateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementAdminServiceValidateOrganisationsOrganisationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<OrganisationManagementOrganisationsValidationResultModel>>;
  public validateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementAdminServiceValidateOrganisationsOrganisationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<OrganisationManagementOrganisationsValidationResultModel>>;
  public validateOrganisationsOrganisations(
    requestParameters?: OrganisationManagementAdminServiceValidateOrganisationsOrganisationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const organisationManagementOrganisationsValidateOrganisationsModel = requestParameters?.organisationManagementOrganisationsValidateOrganisationsModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/organisation-management/admin/v1/organisations/validate`;
    return this.httpClient.request<OrganisationManagementOrganisationsValidationResultModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: organisationManagementOrganisationsValidateOrganisationsModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
