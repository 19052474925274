import { TreeNode } from '../route-builder';

export const EmployeeDetailOverviewModule = {
  segment: 'employee-detail-overview',
  children: {
    EmployeeDetailOverviewComponent: { segment: '' },
    EmployeeProfileComponent: { segment: 'profile' },
    DocumentCenterComponent: { segment: 'documents' }
  }
} as const satisfies TreeNode;
