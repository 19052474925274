export enum AllocationFilterOptions {
  MeritPopulation = 'MeritPopulation',
  EquityPopulation = 'EquityPopulation',
  ManagementGroup = 'ManagementGroup',
  PositionClass = 'PositionClass',
  MercerJobCode = 'MercerJobCode',
  OrgCluster = 'OrgCluster',
  OrgClusterNode = 'OrgClusterNode',
  GeoCluster = 'GeoCluster',
  GeoClusterNode = 'GeoClusterNode',
  Country = 'Country',
  Gid = 'Gid',
  OrgCode = 'OrgCode',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  Workflow = 'Workflow',
  ContractStatus = 'ContractStatus',
  Are = 'Are',
  IsFlagged = 'IsFlagged'
}

export enum MeritSupportFilterOptions {
  CountryInverted = 'CountryInverted'
}

export enum MeritAllocationFilterOptions {
  IsMeritExcluded = 'IsMeritExcluded',
  IsEquityExcluded = 'IsEquityExcluded'
}

export enum AllocationSortOptions {
  GeoCluster = 'GeoCluster',
  GeoClusterNode = 'GeoClusterNode',
  Gid = 'Gid',
  ContractStatus = 'ContractStatus'
}

export enum SortingType {
  ASC = 'asc',
  DESC = 'desc'
}

export enum MercerMasterDataFilterOptions {
  gid = 'queryGid',
  firstname = 'queryFirstname',
  lastname = 'queryLastname',
  orgCode = 'queryOrgCodes',
  orgSize = 'queryOrgSizes',
  country = 'queryCountries',
  are = 'queryAreCodes',
  jobCode = 'queryJobCodes',
  jobCodeValidFrom = 'queryJobCodeValidFrom',
  jobCodeValidTo = 'queryJobCodeValidTo',
  mercerJcValidated = 'queryJobCodeIsValidated',
  mercerFamilyName = 'queryFamilyNames',
  mercerSubFamilyName = 'querySubFamilyNames',
  mercerSpecializationName = 'querySpecializationNames',
  mercerCareerStream = 'queryCareerStreams',
  mercerCareerLevelName = 'queryCareerLevelNames',
  mercerPositionClass = 'queryPositionClasses',
  mercerValidFrom = 'queryPositionClassValidFrom',
  mercerValidTo = 'queryPositionClassValidTo',
  mercerPcValidated = 'queryPositionClassIsValidated',
  mercerInternalLevel = 'queryInternalLevels'
}

export enum MercerMasterDataSortOptions {
  gid = 'Gid',
  firstname = 'Firstname',
  lastname = 'Lastname',
  orgCode = 'OrgCode',
  orgSize = 'OrgSize',
  country = 'Country',
  are = 'ARE',
  jobCode = 'JobCode',
  jobCodeValidFrom = 'JobCodeValidFrom',
  jobCodeValidTo = 'JobCodeValidTo',
  mercerJcValidated = 'JobCodeIsValidated',
  mercerFamilyName = 'FamilyName',
  mercerSubFamilyName = 'SubFamilyName',
  mercerSpecializationName = 'SpecializationName',
  mercerCareerStream = 'CareerStream',
  mercerCareerLevelName = 'CareerLevelName',
  mercerPositionClass = 'PositionClass',
  mercerValidFrom = 'PositionClassValidFrom',
  mercerValidTo = 'PositionClassValidTo',
  mercerPcValidated = 'PositionClassIsValidated',
  mercerInternalLevel = 'InternalLevel'
}

export enum MercerMasterDataFieldValuesFilterOptions {
  gid = 'gid',
  firstname = 'firstname',
  lastname = 'lastname',
  orgCode = 'orgCode',
  orgSize = 'orgSize',
  country = 'country',
  are = 'are',
  jobCode = 'jobCode',
  jobCodeValidFrom = 'jobCodeValidFrom',
  jobCodeValidTo = 'jobCodeValidTo',
  mercerJcValidated = 'jobCodeIsValidated',
  mercerFamilyName = 'familyName',
  mercerSubFamilyName = 'subFamilyName',
  mercerSpecializationName = 'specializationName',
  mercerCareerStream = 'careerStream',
  mercerCareerLevelName = 'careerLevelName',
  mercerPositionClass = 'positionClass',
  mercerValidFrom = 'positionClassValidFrom',
  mercerValidTo = 'positionClassValidTo',
  mercerPcValidated = 'positionClassIsValidated',
  mercerInternalLevel = 'internalLevel'
}

export enum PositionEvaluationFilterOptions {
  NewPositionClass = 'NewPositionClass',
  OriginalProposedPositionClass = 'OriginalProposedPositionClass',
  OrgLevel = 'OrgLevel',
  MercerJobCode = 'MercerJobCode',
  OrgSize = 'OrgSize',
  IncumbentGid = 'IncumbentGid',
  ValidationManagerGid = 'ValidationManagerGid',
  OrgCode = 'OrgCode',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  PositionType = 'PositionType',
  CountryIsoCode = 'CountryIsoCode',
  IsLocked = 'IsLocked',
  MercerJobFamily = 'MercerJobFamily',
  MercerJobSpecialization = 'MercerJobSpecialization',
  MercerJobSubFamily = 'MercerJobSubFamily',
  IncumbentAre = 'IncumbentAre',
  Tag = 'Tag'
}

export enum PositionEvaluationSortOptions {
  NewPositionClass = 'NewPositionClass',
  OriginalProposedPositionClass = 'OriginalProposedPositionClass',
  OrgLevel = 'OrgLevel',
  MercerJobCode = 'MercerJobCode',
  OrgSize = 'OrgSize'
}

export enum StandingPositionEvaluationFilterOptions {
  PositionClass = 'PositionClass',
  OrgLevel = 'OrgLevel',
  MercerJobCode = 'MercerJobCode',
  Country = 'Country',
  OrgSize = 'OrgSize',
  Gid = 'Gid',
  Are = 'Are',
  OrgCode = 'OrgCode',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  LineManagerGid = 'LineManagerGid',
  MercerJobFamily = 'MercerJobFamily',
  MercerJobSubFamily = 'MercerJobSubFamily',
  MercerJobSpecialization = 'MercerJobSpecialization',
  IsHead = 'IsHead'
}

export enum StandingPositionEvaluationSortOptions {
  PositionClass = 'PositionClass',
  OrgLevel = 'organisation.OrgLevel',
  OrgCode = 'organisation.OrgCode'
}

export enum SuccessionManagementSupportListViewFilterOptions {
  FirstnameStartsWith = 'FirstnameStartsWith',
  LastnameStartsWith = 'LastnameStartsWith',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  OrgNameStartsWith = 'OrgNameStartsWith',
  GidStartsWith = 'GidStartsWith',
  PlaceOfAction = 'PlaceOfAction',
  OrgCodes = 'OrgCodes',
  OrgNames = 'OrgNames',
  TalentPoolTitle = 'TalentPoolTitle',
  RecommendationState = 'RecommendationState',
  IsWildcardCandidateBoolean = 'IsWildcardCandidateBoolean',
  AreCodes = 'AreCodes',
  ReviewExcluded = 'ReviewExcluded'
}

export enum SuccessionManagementSupportAssigneesFilterOptions {
  FirstnameStartsWith = 'FirstnameStartsWith',
  LastnameStartsWith = 'LastnameStartsWith',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  OrgNameStartsWith = 'OrgNameStartsWith',
  GidStartsWith = 'GidStartsWith',
  PlaceOfAction = 'PlaceOfAction',
  OrgCodes = 'OrgCodes',
  OrgNames = 'OrgNames',
  AreCodes = 'AreCodes'
}

export enum SuccessionManagementSupportTalentSortOptions {
  'Employee.firstname' = 'Firstname',
  'Employee.lastname' = 'Lastname',
  'Employee.organisation.orgCode' = 'OrgCode',
  'Employee.organisation.orgName' = 'OrgName',
  'Employee.placeOfAction' = 'PlaceOfAction',
  'Employee.are.areCode' = 'AreCode'
}

export enum SuccessionManagementTalentPoolSortOptions {
  Title = 'Title'
}

export enum SuccessionManagementMyEmployeesViewSortOptions {
  'Employee.firstname' = 'Firstname',
  'Employee.lastname' = 'Lastname',
  'Employee.organisation.orgCode' = 'OrgCode',
  'Employee.organisation.orgName' = 'OrgName',
  'Employee.placeOfAction' = 'PlaceOfAction',
  'Employee.are.areCode' = 'AreCode'
}

export enum SuccessionManagementMyEmployeesViewFilterOptions {
  FirstnameStartsWith = 'FirstnameStartsWith',
  LastnameStartsWith = 'LastnameStartsWith',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  OrgNameStartsWith = 'OrgNameStartsWith',
  GidStartsWith = 'GidStartsWith',
  TalentPoolTitle = 'TalentPoolTitle',
  RecommendationState = 'RecommendationState',
  OrgCodes = 'OrgCodes',
  OrgNames = 'OrgNames',
  PlaceOfAction = 'PlaceOfAction',
  AreCodes = 'AreCodes'
}

export enum SuccessionManagementOrganizationViewFilterOptions {
  FirstnameStartsWith = 'FirstnameStartsWith',
  LastnameStartsWith = 'LastnameStartsWith',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  OrgNameStartsWith = 'OrgNameStartsWith',
  GidStartsWith = 'GidStartsWith',
  PlaceOfAction = 'PlaceOfAction',
  OrgCodes = 'OrgCodes',
  OrgNames = 'OrgNames',
  TalentPoolTitle = 'TalentPoolTitle',
  RecommendationState = 'RecommendationState',
  AreCodes = 'AreCodes'
}

export enum SuccessionManagementOrganizationViewSortOptions {
  'Employee.firstname' = 'Firstname',
  'Employee.lastname' = 'Lastname',
  'Employee.organisation.orgCode' = 'OrgCode',
  'Employee.organisation.orgName' = 'OrgName',
  'Employee.placeOfAction' = 'PlaceOfAction',
  'Employee.are.areCode' = 'AreCode'
}

export enum SpecialPaymentFilterOptions {
  OrgCode = 'OrgCode',
  OrgLevel = 'OrgLevel',
  ProposalState = 'ProposalState',
  CurrentApprover = 'CurrentApprover',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  PlaceOfAction = 'PlaceOfAction',
  SpecialPaymentReason = 'SpecialPaymentReason',
  ManagerGid = 'ManagerGid',
  HeadOfHierarchyGid = 'HeadOfHierarchyGid',
  RegionalHRHeadGid = 'RegionalHRHeadGid',
  NMinus2ManagerGid = 'NMinus2ManagerGid',
  HRBusinessPartnerGid = 'HRBusinessPartnerGid'
}

export enum SpecialPaymentSortOptions {
  OrgCode = 'OrgCode',
  OrgLevel = 'OrgLevel',
  ManagerGid = 'ManagerGid',
  ProposalState = 'ProposalState',
  CurrentApprover = 'CurrentApprover'
}

export enum OrgPlanningSupportFilterOptions {
  OrgCodes = 'OrgCodes'
}

export enum HeadcountApprovalListViewFilterOptions {
  OrgLevel = 'OrgLevel',
  OrgCode = 'OrgCode',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  RequesterGid = 'RequesterGid',
  PositionClass = 'PositionClass'
}

export enum HeadcountApprovalFilterOptions {
  Search = 'Search',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  Name = 'Name',
  DisplayName = 'DisplayName',
  ClusterId = 'ClusterId',
  ClusterIds = 'ClusterIds',
  ClusterGroupId = 'ClusterGroupId',
  Type = 'Type',
  IsDisplayNameRequired = 'IsDisplayNameRequired',
  MustBeMemberOf = 'MustBeMemberOf',
  ClusterGroupIds = 'ClusterGroupIds'
}

export enum HeadcountApprovalSortOptions {
  OrgLevel = 'OrgLevel',
  OrgCode = 'OrgCode',
  PositionClass = 'PositionClass',
  CreatedAt = 'CreatedAt'
}

export enum IncentivePartnerReassignmentsSortOptions {
  Gid = 'Gid',
  Firstname = 'Firstname',
  Lastname = 'Lastname',
  Reason = 'Reason',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  Action = 'Action',
  AreNew = 'AreNew',
  AreCurrent = 'AreCurrent',
  CountryNew = 'CountryNew',
  CountryCurrent = 'CountryCurrent',
  OrgCodeNew = 'OrgCodeNew',
  OrgCodeCurrent = 'OrgCodeCurrent',
  PlanNameNew = 'PlanNameNew',
  PlanNameCurrent = 'PlanNameCurrent',
  IncentivePaymentTypeCurrent = 'IncentivePaymentTypeCurrent',
  IncentivePaymentTypeNew = 'IncentivePaymentTypeNew',
  OrgClusterCurrent = 'OrgClusterCurrent',
  OrgClusterNew = 'OrgClusterNew',
  ManagementType = 'ManagementType',
  NewManagementType = 'NewManagementType'
}

export enum IncentivePartnerReassignmentsFilterOptions {
  GidContains = 'GidContains',
  FirstnameContains = 'FirstnameContains',
  LastnameContains = 'LastnameContains',
  StartDateContains = 'StartDateContains',
  EndDateContains = 'EndDateContains',
  OrgCodeCurrent = 'OrgCodeCurrent',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OrgCodeNew = 'OrgCodeNew',
  IncentivePaymentTypeCurrent = 'IPT',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  IncentivePaymentTypeNew = 'IPT',
  AreCurrent = 'Are',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  AreNew = 'Are',
  CountryCurrent = 'Country',
  CountryNew = 'NewCountry',
  PlanNameCurrent = 'Plan',
  PlanNameNew = 'NewPlan',
  OrgClusterCurrent = 'OrgCluster',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OrgClusterNew = 'OrgCluster',
  ManagementType = 'ManagementType',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  NewManagementType = 'ManagementType'
}

export enum IncentivePartnerMonitoringFilterOptions {
  GidContains = 'GidContains',
  AssignmentIdContains = 'AssignmentIdContains',
  FirstnameContains = 'FirstnameContains',
  LastnameContains = 'LastnameContains',
  PlanTitleContains = 'PlanTitleContains',
  AreContains = 'AreContains',
  OrgCodeContains = 'OrgCodeContains',
  TemplateContains = 'TemplateContains',
  Workflow = 'Workflow',
  RecordState = 'RecordState',
  Country = 'Country'
}

export enum RestructuringFilterOptions {
  Country = 'Country',
  Are = 'Are',
  LineManagerGid = 'LineManagerGid',
  OrgCode = 'OrgCode',
  OrgCodeStartsWith = 'OrgCodeStartsWith'
}

export enum HeadcountOverviewTeamListViewFilterOptions {
  Are = 'Are',
  OrgCode = 'OrgCode',
  OrgCodeStartsWith = 'OrgCodeStartsWith',
  BusinessArea = 'BusinessArea',
  Region = 'Region (or cluster name)',
  CountryName = 'CountryName',
  Location = 'Location(City)'
}

export enum RestructuringSortOptions {
  Name = 'Name',
  OrgCode = 'OrgCode'
}

export enum HeadcountOverviewTeamListViewSortOptions {
  Are = 'Are',
  OrgCode = 'OrgCode',
  BusinessArea = 'BusinessArea',
  CountryName = 'CountryName',
  Region = 'Region',
  Location = 'Location(City)',
  FullName = 'FullName',
  Gid = 'Gid'
}
