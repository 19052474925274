import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RequestSummaryCustomType } from '@coin/shared/util-enums';
import { MassReqResponse } from '@coin/shared/util-models';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { Observable } from 'rxjs';

interface Data {
  result: MassReqResponse;
  customType?: RequestSummaryCustomType;
  customHeaderTitle?: string;
  customFailureCountTitle?: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-request-summary',
  templateUrl: './request-summary.component.html',
  styleUrls: ['./request-summary.component.scss'],
  standalone: false
})
export class RequestSummaryComponent {
  public customType = RequestSummaryCustomType;

  public areWarningsVisible = false;
  public areFailuresVisible = true;

  getAccumulatedSuccessCount(response: MassReqResponse): number {
    return response?.updatedCount + response?.skippedCount + response?.skipCount + response?.createdCount || 0;
  }

  public static openDialog(dialog: MatDialog, data: Data): Observable<undefined> {
    return dialog
      .open<RequestSummaryComponent, Data, undefined>(RequestSummaryComponent, {
        disableClose: true,
        data
      })
      .beforeClosed();
  }

  constructor(
    private dialogRef: MatDialogRef<RequestSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data
  ) {}

  public getItemOverviewText(item: unknown): string {
    return Object.keys(item)
      .map(key => `${TinyHelpers.camelCaseToText(key)}: ${Array.isArray(item[key]) ? item[key].join(', ') : item[key]}`)
      .join(', ');
  }

  close(): void {
    this.dialogRef.close();
  }

  public toggleWarningsView(): void {
    this.areFailuresVisible = this.areWarningsVisible;
    this.areWarningsVisible = !this.areWarningsVisible;
  }

  public toggleFailuresView(): void {
    this.areWarningsVisible = this.areFailuresVisible;
    this.areFailuresVisible = !this.areFailuresVisible;
  }
}
