import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { DragDropService } from './drag-drop.service';

/** Enables dragging of attached element */
@Directive({
  selector: '[coinDrag]',
  standalone: false
})
export class DragDirective {
  /** Item (data) that should be dragged with the element */
  @Input({ required: true, alias: 'coinDrag' }) dragItem: unknown;
  @Input() dragDisabled = false;
  /** Preview element that is displayed during dragging. (Useful for e.g. drag handles) If not provided, uses the attached element.  */
  @Input() dragPreview: HTMLElement;

  @HostBinding('style.cursor')
  get cursor(): string {
    return this.dragDisabled ? '' : 'move';
  }

  @HostBinding('attr.draggable')
  get isDraggable(): string {
    return this.dragDisabled ? '' : 'true';
  }

  @HostListener('dragstart', ['$event'])
  public startDragging(e: DragEvent): void {
    if (this.dragPreview) {
      e.dataTransfer.setDragImage(this.dragPreview, 0, 0);
    }

    this.dragDropService.startDragging({ item: this.dragItem, element: this.dragPreview ?? this.elem.nativeElement });
  }

  constructor(
    private elem: ElementRef<HTMLElement>,
    private dragDropService: DragDropService
  ) {}
}
