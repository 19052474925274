import { Component, DestroyRef, EventEmitter, forwardRef, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GbrDocumentsService } from '@coin/modules/gbr/data-access';
import { GbrFile } from '@coin/modules/gbr/util';
import { Fn } from '@coin/shared/util-models';

@Component({
  selector: 'coin-siemens-energy-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true
    }
  ],
  standalone: false
})
export class FileInputComponent implements ControlValueAccessor {
  @Output() delete = new EventEmitter<void>();

  public disabled = false;

  private file: GbrFile = null;

  set value(val: GbrFile) {
    this.file = val;
    this.onChange(val);
  }

  get value(): GbrFile {
    return this.file;
  }

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  constructor(
    private gbrFileService: GbrDocumentsService,
    private destroyRef: DestroyRef
  ) {}

  public handleFiles(files: FileList): void {
    const file: File = files['0'];
    if (!file) {
      return;
    }

    const gbrFile: GbrFile = {
      file,
      fileName: file.name,
      fileType: file.type,
      sizeInBytes: file.size
    };

    this.writeValue(gbrFile);
    this.onTouch(gbrFile);
  }

  public remove(): void {
    this.delete.emit();
  }

  public getFileSize(file: GbrFile): string {
    return this.bytesToSize(file.sizeInBytes);
  }

  private bytesToSize(bytes: number): string {
    if (!bytes && bytes !== 0) {
      return '';
    }
    if (bytes === 0) {
      return '0 Byte';
    }

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`;
  }

  public getFileType(file: GbrFile): string {
    const nameSplit = file.fileName.split('.');
    return nameSplit ? nameSplit[nameSplit.length - 1] : 'pdf';
  }

  public download(): void {
    if (!this.file.filePath) {
      return;
    }

    this.gbrFileService.downloadGbrDocument(this.file.filePath).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public writeValue(value: GbrFile): void {
    this.value = value;
  }

  public registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
