import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { LightWeightOperator } from '../v2-lightweight-rule-engine.types';

@Component({
  selector: 'coin-v2-lightweight-operator-icon',
  templateUrl: './operator-icon.component.html',
  styleUrls: ['./operator-icon.component.scss'],
  imports: [MatIconModule]
})
export class V2LightweightOperatorIconComponent {
  @Input() operator: LightWeightOperator;
}
