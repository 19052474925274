import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'coin-v2-button',
  imports: [MatIconModule],
  templateUrl: './v2-button.component.html',
  styleUrls: ['./v2-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2ButtonComponent {
  @Input() text: string;
  @Input() disabled = false;
  @Input() icon: string;
  @Input() svgIcon: string;
  @Input() iconPos: ButtonIconPosition = 'left';
  @Input({ required: true }) type: ButtonType;

  @HostBinding('class')
  private get classes(): string {
    // currently using this name for legacy support, in the future use `type-${this.type}`
    return `${this.type}`;
  }
}

export const BUTTON_TYPES = ['primary', 'secondary', 'white', 'outline'] as const;
export type ButtonType = (typeof BUTTON_TYPES)[number];

export const BUTTON_ICON_POSITIONS = ['left', 'right'] as const;
export type ButtonIconPosition = (typeof BUTTON_ICON_POSITIONS)[number];
