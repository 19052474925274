import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { V2DualPillComponent } from '../dual-pill/v2-dual-pill.component';

@Component({
  selector: 'coin-update-notifier',
  imports: [CommonModule, TranslateModule, V2DualPillComponent, MatTooltip],
  templateUrl: './update-notifier.component.html',
  styleUrl: './update-notifier.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateNotifierComponent {}
