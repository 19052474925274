import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, ElementRef, Inject, Input, OnChanges, OnDestroy, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LottieFilesService } from '@coin/shared/data-access';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'coin-anim-icon',
  templateUrl: './anim-icon.component.html',
  styleUrls: ['./anim-icon.component.scss'],
  standalone: false
})
export class AnimIconComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() svgIcon: string;
  @Input() animation: boolean;
  @Input() delay = 0;
  @Input() delayend = 0;
  @Input() loop = true;
  @Input() rotation = 0;
  @Input() autoplay = false;
  @Input() speed = 1;
  @Input() startFrame = 0;
  @Input() hoverAnimate = false;
  @Input() segment: unknown;
  @ViewChild('animsvg') animsvg: ElementRef;

  private rndNum: string = uuid();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private lottie: any;

  constructor(
    private lottieFilesService: LottieFilesService,
    private destroyRef: DestroyRef,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    if (isPlatformBrowser(platformId)) {
      this.lottie = window?.['lottie'];
    }
  }

  ngOnDestroy(): void {
    if (this.lottie?.destroy) {
      this.lottie?.destroy(this.rndNum);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.animation && changes.animation.currentValue === true) {
        setTimeout(() => {
          if (!this.segment && this.lottie?.play) {
            this.lottie?.play(this.rndNum);
          }
        }, this.delay);
      }
      if (changes.animation && changes.animation.currentValue === false) {
        setTimeout(() => {
          if (this.lottie?.stop && this.lottie?.goToAndStop) {
            this.lottie?.stop(this.rndNum);
            this.lottie?.goToAndStop(this.startFrame, true, this.rndNum);
          }
        }, this.delayend);
      }
    }
  }

  ngAfterViewInit(): void {
    const existingLSvg = this.lottieFilesService.getLottieFile(this.svgIcon);
    if (existingLSvg) {
      this.handleLottieContent(existingLSvg);
    } else {
      this.lottieFilesService
        .getLottieJson(this.svgIcon)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(lottieSvg => {
          this.lottieFilesService.setLottieFile(this.svgIcon, lottieSvg);
          this.handleLottieContent(lottieSvg);
        });
    }
  }

  public handleLottieContent(file: unknown): void {
    if (this.lottie?.loadAnimation) {
      const anim = this.lottie?.loadAnimation({
        container: this.animsvg.nativeElement, // the dom element that will contain the animation
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
        name: this.rndNum,
        animationData: file
        // path: `assets/svgs/icon-data/${this.svgIcon}.json` // the path to the animation json
      });
      this.lottie?.setSpeed(this.speed);
      if (this.segment) {
        setTimeout(() => {
          anim.playSegments(this.segment, false);
          setTimeout(() => {
            this.lottie?.goToAndStop(this.segment[1], true, this.rndNum);
          }, 800);
        }, this.delay);
      } else {
        this.lottie?.goToAndStop(this.startFrame, true, this.rndNum);
      }
      if (this.autoplay || this.animation) {
        this.lottie?.play(this.rndNum);
      }
    }
  }

  public mEnter(): void {
    if (this.hoverAnimate) {
      this.lottie?.play(this.rndNum);
    }
  }

  public mLeave(): void {
    if (this.hoverAnimate) {
      this.lottie?.stop(this.rndNum);
      if (this.startFrame) {
        this.lottie?.goToAndStop(this.startFrame, true, this.rndNum);
      }
    }
  }
}
