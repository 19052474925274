import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'coin-v2-dialog-footer',
  templateUrl: './v2-dialog-footer.component.html',
  styleUrl: './v2-dialog-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class V2DialogFooterComponent {
  @Input() color: FooterStyle = 'gradient';
  @Input() padding: FooterPadding = 'default';
  @Input() rounding: FooterRounding = 'default';
  @Input() gap: FooterGap = 'default';

  @HostBinding('class') get className(): string {
    return `footer-${this.color} padding-${this.padding} rounding-${this.rounding} gap-${this.gap}`;
  }
}

export const FOOTER_STYLES = ['white', 'gradient'] as const;
export type FooterStyle = (typeof FOOTER_STYLES)[number];

export const FOOTER_PADDINGS = ['none', 'default', 'large'] as const;
export type FooterPadding = (typeof FOOTER_PADDINGS)[number];

export const FOOTER_ROUNDINGS = ['none', 'default'] as const;
export type FooterRounding = (typeof FOOTER_ROUNDINGS)[number];

export const FOOTER_GAPS = ['default', 'large'] as const;
export type FooterGap = (typeof FOOTER_GAPS)[number];
