import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { toObservable } from '@angular/core/rxjs-interop';
import { catchError, Observable, of, ReplaySubject, share, switchMap, tap, timer } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@coin/shared/util-environments';
import { map } from 'rxjs/operators';

/** Cached bas64 images by gid */
const imageCache: { [gid: string]: Observable<string> } = {};

@Component({
  selector: 'coin-employee-picture',
  imports: [CommonModule],
  templateUrl: './employee-picture.component.html',
  styleUrl: './employee-picture.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmployeePictureComponent {
  gid = input.required<string>();
  size = input<PictureSize>('medium');
  background = input<PictureBackground>('grey');

  protected image$ = toObservable(this.gid).pipe(switchMap(gid => this.getImage(gid)));

  protected userPlaceholder = 'assets/images/user-placeholder.svg';

  constructor(private http: HttpClient) {}

  private getImage(gid: string): Observable<string> {
    if (!gid) return of(this.userPlaceholder);
    if (imageCache[gid]) return imageCache[gid];

    const params = new HttpParams({ fromObject: { gid } });
    const image$ = this.http.get<{ img: string }>(`${environment.api.imagesService}images/employee-picture`, { params }).pipe(
      map(response => response.img),
      catchError(() => of(null)),
      share({
        connector: () => new ReplaySubject(0),
        resetOnError: false,
        resetOnComplete: () => timer(600_000).pipe(tap(() => delete imageCache[gid])), // reset observable and clear cache after 10min (just for smoother loading; service worker handles long-term caching)
        resetOnRefCountZero: false
      })
    );
    imageCache[gid] = image$;
    return image$;
  }
}

export const PICTURE_SIZES = ['tiny', 'small', 'medium', 'large'] as const;
export type PictureSize = (typeof PICTURE_SIZES)[number];

export const PICTURE_BACKGROUNDS = ['none', 'grey'] as const;
export type PictureBackground = (typeof PICTURE_BACKGROUNDS)[number];
