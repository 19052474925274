import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LANGUAGE_COUNTRY_MAPPING } from '@coin/shared/util-models';
import { TranslateLanguagePipe } from '@coin/shared/util-pipes';
import { FlagSize, V2CountryFlagComponent } from '../country-flag/v2-country-flag.component';

@Component({
  selector: 'coin-v2-language-flag',
  imports: [V2CountryFlagComponent, MatTooltipModule, TranslateLanguagePipe],
  templateUrl: './v2-language-flag.component.html',
  styleUrls: ['./v2-language-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class V2LanguageFlagComponent {
  @Input({ required: true }) size: FlagSize;
  @Input({ required: true, transform: (s: string) => s.toLowerCase() }) language: string;
  @Input() showTooltip = false;

  protected get languageIdentifier(): string {
    const country = LANGUAGE_COUNTRY_MAPPING[this.language];
    if (!country) {
      console.error(`Unknown language-code: ${this.language}`);
    }
    return country;
  }
}
