let base = 'https://coin.siemens-energy.com';
if (typeof window !== 'undefined') {
  base = window.location.origin;
}

const apiBaseUrl = `${base}/ecs`;

export const environment = {
  production: true,
  coinAssetsPath: 'https://d30qz857dkw45t.cloudfront.net',
  coinImagePath: 'https://d19cf2p6f5tf5t.cloudfront.net/',
  authentication: {
    scopes: 'openid profile email',
    issuer: 'https://login.microsoftonline.com/common/v2.0/'
  },
  customerAuthentication: {
    callbackUrl: `https://coin.siemens-energy.com/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  adminAuthentication: {
    callbackUrl: `https://admin.coin.siemens-energy.com/auth/callback`,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  cosmosAuthentication: {
    callbackUrl: 'https://cosmos.siemens-energy.cloud/auth/callback',
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b'
  },
  importerAuthentication: {
    enabled: true,
    clientId: '13d3841f-1c57-4069-be8a-36d3f759786b',
    callbackUrl: `https://importer.coin.siemens-energy.com/auth/callback`,
    tokenBlacklist: [
      '.s3.eu-central-1.amazonaws.com',
      'https://de0w05fuukbfv.cloudfront.net',
      'https://d149pn5xqxpybr.cloudfront.net',
      'https://d3eaunka2bmq7b.cloudfront.net',
      'http://localhost:8000',
      'https://d375j2sk9axnmo.cloudfront.net/',
      'https://d2kfj1rgt6hh2u.cloudfront.net/',
      'https://d2fq1hec2h85fe.cloudfront.net/'
    ]
  },
  baseButtonColorLight: false,
  api: {
    baseUrl: apiBaseUrl,
    letterServiceUrl: `${apiBaseUrl}/letterservice`,
    pdfCreationService: `${base}/api/serverless/pdf_creation_service`,
    documentsUrl: `${base}/api/serverless/document_service`,
    exportDownloadServiceUrl: `${base}/api/serverless/file_download_service`,
    imagesService: `${base}/api/serverless/image_service/`,
    cmsService: `${base}/api/serverless/cms_service/`,
    cmsServiceProd: 'https://coin.siemens-energy.com/api/serverless/cms_service/',
    cosmosSearchService: `${base}/api/serverless/search_service/`,
    importerService: `${base}/api/serverless/importer_service/api/v1`,
    storageService: `${base}/api/serverless/storage_service`
  },
  device: 'desktop',
  cmsContentProdBucketCloudfront: 'https://cms.coin.siemens-energy.com/',
  cmsContentStageBucketCloudfront: 'https://cms.coin.siemens-energy.com/',
  documentDownloadCloudfront: 'https://d2fq1hec2h85fe.cloudfront.net/',
  type: 'Prod',
  setProfilePictureEndpoint: 'https://profile.hrs.siemens.cloud/',
  adminUrl: 'https://admin.coin.siemens-energy.com/',
  customerUrl: 'https://coin.siemens-energy.com/',
  cosmosUrl: 'https://cosmos.siemens-energy.cloud/',
  importerTableLimit: 25,
  subscriptionIntervalInMs: 5000,
  icon: null,
  dataImportBucket: 'experts-prod-dataimports',
  avatureJobUrl: 'http://siemensenergy.avature.net/internalcareers/FolderDetail/',
  helpdeskUrl: 'https://siemensenergy.service-now.com/sp?id=sc_cat_item&sys_id=29cfcd841bac061080ff62406b4bcbf1&referrer=popular_items'
};
