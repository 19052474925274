import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LottieFilesService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { v4 as uuid } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let lottie: any;

/**
 * @deprecated Use `coin-v2-button` instead.
 */
@Component({
  selector: 'coin-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss', './button-icons.scss'],
  standalone: false
})
export class ActionButtonComponent implements AfterViewInit, OnInit {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click = new EventEmitter<void>();
  @Output() mouseEnter = new EventEmitter<void>();
  @Output() mouseLeave = new EventEmitter<void>();
  @Input() disabled = false;
  @Input() loading = false;

  // STYLING
  @Input() addClass: string;
  @Input() circle = false;

  // ICONS
  @Input() svgIcon: string;
  @Input() svgSpeed = 0.6;
  @Input() iconTextDistance = 20;
  @Input() iconTextMargin = 8;
  @Input() iconPos: 'left' | 'right' = 'right';

  @Input() noicon = false;
  @Input() arrowPos: 'left' | 'right' = 'right';
  @Input() icon = 'arrow';
  @Input() matIcon = '';
  @Input() matIconTextMargin = 10;
  @Input() height = 18;
  @Input() ellipsis = false;
  @Input() small = false;
  @Input() bgColor?: string;
  @Input() matIconOutlined = false;

  @ViewChild('svgicon') svgicon: ElementRef;
  @ViewChild('button') button: ElementRef;

  @HostListener('mouseenter')
  public onHostMouseEnter(): void {
    this.mouseEnter.emit();
  }
  @HostListener('mouseleave')
  public onHostMouseLeave(): void {
    this.mouseLeave.emit();
  }

  ripple: HTMLDivElement;
  private lottieIconId: string;

  get iconClass() {
    return this.noicon || !!this.svgIcon ? '' : `${this.icon}--${this.arrowPos}`;
  }

  get whiteText() {
    return this.elementRef.nativeElement.classList.contains('primary');
  }

  constructor(
    private lottieFilesService: LottieFilesService,
    private elementRef: ElementRef,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.lottieIconId = uuid();
  }

  ngAfterViewInit(): void {
    this.addSvgIcon();
    this.addSvgIcon();
  }

  private addSvgIcon(): void {
    if (!this.svgIcon) {
      return;
    }

    const newFullIconTitle = this.svgIcon + (this.isWhiteIcon() ? '-white' : '');
    const existingLSvg = this.lottieFilesService.getLottieFile(newFullIconTitle);

    if (existingLSvg) {
      this.handleLottieContent(existingLSvg);
    } else {
      this.lottieFilesService
        .getLottieJson(newFullIconTitle)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(lottieSvg => {
          this.lottieFilesService.setLottieFile(newFullIconTitle, lottieSvg);
          this.handleLottieContent(lottieSvg);
        });
    }
  }

  private isWhiteIcon(): boolean {
    if (this.button.nativeElement.parentNode.classList.contains('primary')) {
      return true;
    }
    const whiteColorStyles = ['white', '#fff', 'white', 'rgb(255, 255, 255)'];
    const buttonStyles = window.getComputedStyle(this.button.nativeElement);
    const buttonColorValue = buttonStyles.getPropertyValue('color');

    if (whiteColorStyles.includes(buttonColorValue)) {
      return true;
    }

    return environment.baseButtonColorLight && this.whiteText;
  }

  private handleLottieContent(file): void {
    lottie.destroy(this.lottieIconId);
    lottie.loadAnimation({
      container: this.svgicon.nativeElement, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: this.lottieIconId,
      animationData: file
      // path: `assets/svgs/icon-data/${this.svgIcon}.json` // the path to the animation json
    });

    lottie.setSpeed(this.svgSpeed);

    if (this.svgIcon === 'add') {
      lottie.goToAndStop(27, true, this.lottieIconId);
    }
    if (this.svgIcon === 'print') {
      lottie.goToAndStop(27, true, this.lottieIconId);
    }
    if (this.svgIcon === 'link' || this.svgIcon === 'link-white') {
      lottie.goToAndStop(40, true, this.lottieIconId);
    }
  }

  public mouseEnterLeave(type: string): void {
    if (!this.svgIcon) {
      return;
    }

    if (type === 'enter') {
      lottie.play(this.lottieIconId);
    } else {
      lottie.stop(this.lottieIconId);
      if (this.svgIcon === 'add') {
        lottie.goToAndStop(27, true, this.lottieIconId);
      }
      if (this.svgIcon === 'print') {
        lottie.goToAndStop(27, true, this.lottieIconId);
      }
      if (this.svgIcon === 'link' || this.svgIcon === 'link-white') {
        lottie.goToAndStop(40, true, this.lottieIconId);
      }
    }
  }

  public onClick(e: MouseEvent): void {
    e.stopPropagation();

    if (this.disabled || this.loading) {
      return;
    }
    this.click.emit();
  }
}
