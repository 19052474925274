import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, Observable } from 'rxjs';
import { ConfirmationDialogConfirm, ConfirmationDialogData } from '@coin/shared/util-models';
import { TinyHelpers } from '@coin/shared/util-helpers';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  standalone: false
})
export class ConfirmationDialogComponent<T = string> implements OnInit {
  providedInput = '';
  selectedDropdownValue: T;
  width: string | number = '400px';

  getTranslatedValue(text: string): string {
    const translatedText = text ? this.translateService.instant(text || '', this.data?.msgInterpolationParams) : '';
    const containsMsgValue = translatedText.includes('{msgValue}');
    return containsMsgValue ? translatedText.replace('{msgValue}', this.data?.msgValue?.toString()) : translatedText;
  }

  getHeadlineData(): string {
    return this.data?.headlineData ? `- ${this.data?.headlineData}` : '';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData<T>,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent<T>>
  ) {}

  public static openDialog(dialog: MatDialog, data: ConfirmationDialogData, maxWidth = 'unset', disableClose = true): Observable<ConfirmationDialogConfirm> {
    return dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogConfirm>(ConfirmationDialogComponent, {
        disableClose,
        maxWidth,
        data
      })
      .beforeClosed();
  }

  public ngOnInit(): void {
    this.dialogRef.addPanelClass?.('d1r-dialog-container');
    this.selectedDropdownValue = this?.data?.currentDropdownValue ?? undefined;
    this.providedInput = this.data?.currentInputValue;
    this.width = this.data.width || this.width;
  }

  dropdownDisplayFn(text: string): string {
    return TinyHelpers.pascalcaseToText(text);
  }

  public cancel(): void {
    if (this.data.confirmCancelIfEdited) {
      this.dialog
        .open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogConfirm>(ConfirmationDialogComponent, {
          disableClose: true,
          width: '375px',
          data: {
            headline: 'general.confirmation-dialog.unsaved-changes-confirmation-headline',
            translate: true,
            msg: 'general.confirmation-dialog.unsaved-changes-confirmation-msg',
            confirmMsg: 'general.yes-close',
            cancelMsg: 'general.keep-editing'
          }
        })
        .afterClosed()
        .pipe(
          filter(confirmation => !!confirmation),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe(() => this.dialogRef.close());
    } else {
      this.dialogRef.close();
    }
  }

  public confirm(): void {
    this.dialogRef.close({
      input: this.providedInput,
      dropdownValue: this.selectedDropdownValue,
      clickedButton: 'Confirm'
    } as ConfirmationDialogConfirm);
  }

  public onThirdButtonClick(): void {
    this.dialogRef.close({
      input: this.providedInput,
      dropdownValue: this.selectedDropdownValue,
      clickedButton: 'ThirdButton'
    } as ConfirmationDialogConfirm);
  }
}
