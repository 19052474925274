import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GbrPersonSaveService } from '@coin/modules/gbr/data-management';
import { GbrChildInput, GbrPersonInput, GbrPersonType } from '@coin/modules/gbr/util';
import { BooleanChoice } from '@coin/shared/util-enums';
import { Fn } from '@coin/shared/util-models';
import { flyFromBottomAnim } from '../../animations/flyFromBottom.anim';

@Component({
  selector: 'coin-siemens-energy-person-input',
  templateUrl: './person-input.component.html',
  styleUrls: ['./person-input.component.scss', '../../styles/gbr-data-point.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonInputComponent),
      multi: true
    }
  ],
  animations: [flyFromBottomAnim],
  standalone: false
})
export class PersonInputComponent implements OnInit, ControlValueAccessor {
  @Input() type: GbrPersonType;
  @Input() display = false;
  @Output() remove = new EventEmitter<void>();

  public edit = false;
  public disabled = false;

  public yesNoOptions = [BooleanChoice.YES, BooleanChoice.NO];

  public formPerson: GbrPersonInput | GbrChildInput = {} as never;

  private selectedPerson: GbrPersonInput | GbrChildInput = null;
  set value(val: GbrPersonInput | GbrChildInput) {
    this.selectedPerson = val;

    if (this.selectedPerson) {
      this.formPerson = this.selectedPerson;
    }

    this.onChange(val);
  }

  public get value(): GbrPersonInput | GbrChildInput {
    return this.selectedPerson;
  }

  public get personTypes(): typeof GbrPersonType {
    return GbrPersonType;
  }

  public get isChildInput(): boolean {
    return this.type === GbrPersonType.Child;
  }

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  constructor(private saveService: GbrPersonSaveService) {
    this.saveService.registerSaveListener(() => this.edit && this.save());
  }

  ngOnInit(): void {
    if (this.selectedPerson) {
      this.formPerson = this.selectedPerson;
    } else {
      this.setDefaultPerson();
    }
  }

  private setDefaultPerson(): void {
    if (!this.isChildInput) {
      this.formPerson = {
        gidOrEmail: '',
        firstname: '',
        lastname: '',
        location: {
          sameAsEmployee: true
        }
      };
    } else {
      this.formPerson = {
        sameHousehold: BooleanChoice.NO,
        inEducation: BooleanChoice.NO,
        birthday: null
      };
    }
  }

  public toggleEdit(): void {
    this.edit = !this.edit;
  }

  public removeClick(): void {
    this.remove.emit();
    this.setDefaultPerson();
  }

  public save(): void {
    this.writeValue(this.formPerson);
    this.onTouch(this.formPerson);
    this.toggleEdit();
  }

  public writeValue(value: GbrPersonInput | GbrChildInput): void {
    this.value = value;
  }
  public registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
