import { AfterViewInit, Component, DestroyRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommentType } from '@coin/shared/util-enums';
import { SimplebarAngularComponent } from 'simplebar-angular';
import { CoinUser, Comment, Employee, LetterComment, Todo } from '@coin/shared/util-models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

interface PostLetterCommentService {
  postComment: (letterId: string, comment: LetterComment) => Observable<Comment>;
}
interface PostSeasonRecordCommentService {
  postComment: (recordId: string, comment: Comment) => Observable<Comment>;
}
interface PostMeritRecordCommentService {
  postComment: (seasonId: string, employeeId: string, message: string, reason?: string) => Observable<Comment>;
}

@Component({
  selector: 'coin-siemens-energy-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  standalone: false
})
export class CommentsComponent implements OnInit, AfterViewInit {
  @Input() comments: Array<LetterComment | Comment>;
  @Input() commentService: PostLetterCommentService | PostSeasonRecordCommentService | PostMeritRecordCommentService;
  @Input() todo: Todo; // optional
  @Input() letterId?: string;
  @Input() user: CoinUser;
  @Input() employee?: Employee & { firstName?: string; lastName?: string };
  @Input() employeeId?: string;
  @Input() embedded: boolean;
  @Input() inDialog: boolean;
  @Input() additionalId: string;
  @Input() commentType: CommentType;
  @Output() closeMe = new EventEmitter<void>();
  @Output() add = new EventEmitter<string>();

  @ViewChild('simpleBar') simpleBar: SimplebarAngularComponent;
  public commentText = '';

  get employeeName(): string {
    return this.employee ? `(${this.employee.firstname || this.employee.firstName || ''} ${this.employee.lastname || this.employee.lastName || ''})` : '';
  }

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.comments = this.getSortedComments();
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  public addComment(): void {
    if (this.commentText?.trim()) {
      switch (this.commentType) {
        case CommentType.SeasonRecord:
          this.addSeasonRecordComment();
          break;
        case CommentType.MeritRecord: {
          if (this.letterId) {
            this.addLetterComment();
          } else {
            this.addMeritRecordComment();
          }
          break;
        }
        case CommentType.Letter:
          this.addLetterComment();
          break;
        default:
          this.addGenericComment();
      }
    }
  }

  public addLetterComment(): void {
    const comment = new LetterComment(
      this.createCommentObject({
        linkedTodoId: this.todo?.id
      })
    );

    (this.commentService as PostLetterCommentService)
      .postComment(this.letterId, comment)
      .pipe(
        tap(() => this.addCommentLocally(comment)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public addSeasonRecordComment(): void {
    const comment = this.createCommentObject();

    (this.commentService as PostSeasonRecordCommentService)
      .postComment(this.additionalId || this.letterId, comment)
      .pipe(
        tap(() => this.addCommentLocally(comment)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public addMeritRecordComment(): void {
    const comment = this.createCommentObject();

    (this.commentService as PostMeritRecordCommentService)
      .postComment(this.additionalId, this.employee?.employeeId || this.employeeId, this.commentText)
      .pipe(
        tap(() => this.addCommentLocally(comment)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public addGenericComment(): void {
    this.add.emit(this.commentText);
    this.addCommentLocally(this.createCommentObject());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private createCommentObject(additional?: Record<string, unknown>) {
    return {
      text: this.commentText,
      author: {
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        gid: this.user.gid,
        id: this.user.id
      },
      createdAt: moment().utc().toISOString(),
      ...additional
    };
  }

  private addCommentLocally(comment: Comment | LetterComment): void {
    this.comments.push(comment);
    this.scrollToBottom();
    this.commentText = '';
  }

  public scrollToBottom(): void {
    if (this.simpleBar) {
      setTimeout(() => {
        this.simpleBar?.SimpleBar?.getScrollElement().scrollTo({ top: 50000, behavior: 'smooth' });
      }, 400);
    }
  }

  private getSortedComments(): Array<LetterComment | Comment> {
    if (this.comments?.length) {
      return (this.comments as Array<LetterComment & Comment>).sort((a, b) => moment(a.timestamp || a.createdAt).valueOf() - moment(b.timestamp || b.createdAt).valueOf());
    }
    return this.comments;
  }

  public close(): void {
    this.closeMe.emit();
  }
}
