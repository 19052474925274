import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GetImageWithGidService } from '@coin/modules/auth/data-access';
import { EmployeeWithMaritalStatus } from '@coin/shared/util-models';
import { MasterDataConfig } from './attribute-display.config';

@Component({
  selector: 'coin-siemens-energy-personal-data',
  templateUrl: './personal-data.component.html',
  styleUrls: ['./personal-data.component.scss', '../../styles/gbr-data-point.scss'],
  standalone: false
})
export class PersonalDataComponent implements OnInit {
  @Input() employee: EmployeeWithMaritalStatus;

  public employeeImage: string;
  public masterAttributes = MasterDataConfig;

  constructor(
    private imageService: GetImageWithGidService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.setEmployeeImage();
  }

  private setEmployeeImage(): void {
    if (!this.employee?.gid) {
      return;
    }

    this.imageService
      .getImageWithStore(this.employee.gid)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(image => {
        this.employeeImage = image;
      });
  }
}
