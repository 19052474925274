import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

type ClickedButtonType = 'save' | 'noSave';
interface DialogData {
  title: string;
  msg: string;
  canSave: boolean;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss'],
  standalone: false
})
export class UnsavedChangesDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<UnsavedChangesDialogComponent, ClickedButtonType>
  ) {}

  public static openDialog(dialog: MatDialog, data: DialogData): Observable<ClickedButtonType> {
    return dialog
      .open<UnsavedChangesDialogComponent, DialogData, ClickedButtonType>(UnsavedChangesDialogComponent, {
        disableClose: true,
        maxWidth: 'unset',
        data
      })
      .beforeClosed();
  }

  public proceed(type: ClickedButtonType): void {
    this.dialogRef.close(type);
  }

  public goBack(): void {
    this.dialogRef.close();
  }
}
