import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime, filter } from 'rxjs/operators';
import { DragDropService } from './drag-drop.service';

@Directive({
  selector: '[coinDrop]',
  exportAs: 'coinDrop',
  standalone: false
})
export class DropDirective {
  @Input() dropDisabled = false;
  /** Emits items dropped into the element */
  @Output() coinDrop = new EventEmitter<{ item: unknown; element: HTMLElement }>();
  @Output() dragged = new EventEmitter();

  /** Whether an item is currently dragged over the element */
  get draggedOver(): boolean {
    return this.draggedOver$.value;
  }

  private draggedOver$ = new BehaviorSubject(false);

  constructor(
    private elem: ElementRef<HTMLElement>,
    private dragDropService: DragDropService,
    private zone: NgZone,
    private cd: ChangeDetectorRef
  ) {
    this.enableDropping();
    this.optimizeChangeDetection();
  }

  private enableDropping(): void {
    const [dragover$, drop$, dragenter$, dragleave$] = [
      fromEvent(this.elem.nativeElement, 'dragover'),
      fromEvent(this.elem.nativeElement, 'drop'),
      fromEvent(this.elem.nativeElement, 'dragenter'),
      fromEvent(this.elem.nativeElement, 'dragleave')
    ].map(o$ => o$.pipe(filter(() => !this.dropDisabled)), takeUntilDestroyed());

    /* eslint-disable rxjs-angular/prefer-takeuntil */
    this.zone.runOutsideAngular(() => {
      dragover$.subscribe(event => {
        this.draggedOver$.next(true);
        this.dragged.emit({ event, element: this.dragDropService.draggedItem?.element, item: this.dragDropService.draggedItem?.item });
        event.preventDefault();
      });
      drop$.subscribe(() => {
        this.coinDrop.next(this.dragDropService.draggedItem);
        this.draggedOver$.next(false);
      });
      dragenter$.subscribe(event => {
        this.draggedOver$.next(true);
      });
      dragleave$.subscribe(() => {
        this.draggedOver$.next(false);
      });
    });
  }

  private optimizeChangeDetection(): void {
    this.draggedOver$.pipe(debounceTime(0), distinctUntilChanged(), takeUntilDestroyed()).subscribe(() => this.zone.run(() => this.cd.markForCheck()));
  }
}
