import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeputyService } from '@coin/modules/auth/data-access';
import { LoadingService } from '@coin/shared/data-access';
import { ConfirmationDialogComponent } from '@coin/shared/feature-legacy-components';
import { shrinkExpandAnimation } from '@coin/shared/util-animations';
import { Deputy, EmployeeSlim } from '@coin/shared/util-models';
import { filter, finalize, switchMap, tap } from 'rxjs';
import { ConfirmationAddDeputyDialogComponent } from '../confirmation-add-deputy-dialog/confirmation-add-deputy-dialog.component';

@Component({
  selector: 'coin-siemens-energy-manage-deputies',
  templateUrl: './manage-deputies.component.html',
  styleUrls: ['./manage-deputies.component.scss'],
  animations: [shrinkExpandAnimation],
  standalone: false
})
export class ManageDeputiesComponent implements OnInit {
  public possibleDeputyEmployees: EmployeeSlim[] = [];
  public currentDeputies: Deputy[] = [];
  public areCurrentDeputiesLoading = false;
  public arePossibleDeputiesLoading = false;

  private possibleDeputiesPage = 1;
  private currentDeputiesPage = 1;

  public user = {
    gid: '',
    name: {
      firstname: '',
      lastname: ''
    }
  };

  constructor(
    private dialogRef: MatDialogRef<ManageDeputiesComponent>,
    private dialog: MatDialog,
    private deputyService: DeputyService,
    private loadingService: LoadingService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.loadCurrentDeputies();
  }

  private loadCurrentDeputies(page = 1): void {
    this.areCurrentDeputiesLoading = page === 1;
    this.currentDeputiesPage = page;
    this.deputyService
      .getCurrentDeputies(page)
      .pipe(
        tap(deputies => {
          this.currentDeputies = this.sortByFirstname([...this.currentDeputies, ...deputies]);
        }),
        finalize(() => {
          this.areCurrentDeputiesLoading = false;
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public addDeputy(deputy: Deputy): void {
    this.dialog
      .open(ConfirmationAddDeputyDialogComponent, {
        disableClose: true,
        autoFocus: false
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        tap(() => this.loadingService.present()),
        tap(data => {
          deputy.startsAt = data.startsAt;
          deputy.endsAt = data.endsAt;
        }),
        switchMap(() => this.deputyService.addDeputy({ id: deputy.id, startsAt: deputy.startsAt, endsAt: deputy.endsAt })),
        tap(newDeputy => this.currentDeputies.push(newDeputy)),
        finalize(() => this.loadingService.dismiss())
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  public removeDeputy(deputy: Deputy): void {
    this.dialog
      .open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.info',
          translate: true,
          msg: 'deputy.remove-deputy-msg',
          confirmMsg: 'general.btnConfirm',
          cancelMsg: 'general.btnCancel'
        },
        autoFocus: false
      })
      .afterClosed()
      .pipe(
        filter(result => !!result),
        tap(() => this.loadingService.present()),
        switchMap(() => this.deputyService.removeDeputy(deputy.employeeDeputyId)),
        tap(() => {
          this.currentDeputies = this.currentDeputies.filter(d => d.employeeDeputyId !== deputy.employeeDeputyId);
        }),
        finalize(() => this.loadingService.dismiss())
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  public search(page = 1): void {
    const isFirstSearchPage = page === 1;
    this.arePossibleDeputiesLoading = isFirstSearchPage;
    this.possibleDeputiesPage = page;
    this.deputyService
      .getPotentialDeputies(this.user.gid, this.user.name.firstname, this.user.name.lastname, page)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(employees => {
        this.possibleDeputiesLoaded(employees, isFirstSearchPage);
        this.arePossibleDeputiesLoading = false;
      });
  }

  private possibleDeputiesLoaded(employees: EmployeeSlim[], reset = false): void {
    if (reset) {
      this.possibleDeputyEmployees = [];
    }
    this.possibleDeputyEmployees = this.sortByFirstname([...this.possibleDeputyEmployees, ...employees]);
  }

  private sortByFirstname<T extends { firstname?: string }>(list: T[]): T[] {
    return list.sort((a, b) => a.firstname?.localeCompare(b.firstname));
  }

  public onScrollPossibleDeputies(): void {
    this.possibleDeputiesPage++;
    this.search(this.possibleDeputiesPage);
  }

  public onScrollCurrentDeputies(): void {
    this.currentDeputiesPage++;
    this.loadCurrentDeputies(this.currentDeputiesPage);
  }
}
