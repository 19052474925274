/* eslint-disable max-lines */
import { Component, DestroyRef, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { GeneralUserService } from '@coin/modules/auth/data-access';
import { GbrDocumentsService, GbrService } from '@coin/modules/gbr/data-access';
import { GbrPersonSaveService } from '@coin/modules/gbr/data-management';
import { EmployeeSeasonOverview, GbrChildInput, GbrFile, GbrInput, GbrPersonInput, GbrPersonType, GbrTravelRequestDto, TravelRequestState } from '@coin/modules/gbr/util';
import { ConfirmationDialogComponent, UnsavedChangesDialogComponent } from '@coin/shared/feature-legacy-components';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { EmployeeWithMaritalStatus, EmptyGuid } from '@coin/shared/util-models';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, filter, finalize, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { SimpleGbrInputType } from './inputs/simple-gbr-input/simple-gbr-input.component';

export interface GbrRequestDialogPayload {
  requestId?: string;
  gid: string;
  privacyCheckedDate: string;
  representative: string;
  isAdmin?: boolean;
  seasonId?: string;
  gbrSeasonInfo?: EmployeeSeasonOverview;
}

@Component({
  selector: 'coin-siemens-energy-gbr-request-dialog',
  templateUrl: './gbr-request-dialog.component.html',
  styleUrls: ['./gbr-request-dialog.component.scss'],
  standalone: false
})
export class GbrRequestDialogComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;

  public loadingCount = 0;

  public existingTravelRequest: GbrTravelRequestDto;

  public employeeData: EmployeeWithMaritalStatus;
  public countries: string[];

  public travelPersons: FormGroup<{
    personalInfos: FormGroup<{
      isSingleParent: FormControl<boolean>;
      isShiftWorker: FormControl<boolean>;
      email: FormControl<string>;
      hasParentLeave: FormControl<boolean>;
      hasDisabilities: FormControl<boolean>;
      hasCare: FormControl<boolean>;
    }>;
    vacation: FormGroup<{ country: FormControl<string>; period: FormControl<{ start: Moment; end: Moment }> }>;
    spouse: FormControl<GbrPersonInput>;
    children: FormArray<FormControl<GbrChildInput>>;
    files: FormArray<FormControl<GbrFile>>;
  }>;

  public minOneFileAdded$: Observable<boolean>;
  public vacationPeriodIsFilled$: Observable<boolean>;
  public isFormValid: boolean;

  private lastChildObserver: Subscription;
  private lastFileObserver: Subscription;

  public simpleInputTypes = SimpleGbrInputType;

  public vacationCountryOptions: string[] = [];

  get loading(): boolean {
    return this.loadingCount > 0;
  }

  get wasRequestSubmitted(): boolean {
    return this.existingTravelRequest?.requestState === TravelRequestState.Submitted;
  }

  get spouse(): typeof this.travelPersons.controls.spouse {
    return this.travelPersons.controls.spouse;
  }

  get children(): typeof this.travelPersons.controls.children {
    return this.travelPersons.controls.children;
  }

  get vacationBaseData(): typeof this.travelPersons.controls.vacation {
    return this.travelPersons.controls.vacation;
  }

  get files(): typeof this.travelPersons.controls.files {
    return this.travelPersons.controls.files;
  }

  get inputIsValid(): boolean {
    return this.travelPersons.valid;
  }

  get personTypes(): typeof GbrPersonType {
    return GbrPersonType;
  }

  get hasPreviousStep(): boolean {
    return this.stepper?.selectedIndex > 0;
  }

  get hasNextStep(): boolean {
    return !this.stepper || this.stepper?.selectedIndex < 4;
  }

  get hasChanges(): boolean {
    return this.travelPersons.touched;
  }

  get cannotDelete(): boolean {
    return ![TravelRequestState.Draft, TravelRequestState.Submitted].includes(<TravelRequestState>this.existingTravelRequest?.requestState);
  }

  get isInvalidPeriod(): boolean {
    const start = this.travelPersons?.controls.vacation?.controls.period?.value?.start;
    const end = this.travelPersons?.controls.vacation?.controls.period?.value?.end;
    return start && end && end.diff(start, 'days') < 6;
  }

  public unloadHandler = (event: BeforeUnloadEvent): void => {
    if (this.hasChanges) {
      event.returnValue = false; // determines if the browser question dialog is shown
    }
  };

  constructor(
    public dialogRef: MatDialogRef<GbrRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GbrRequestDialogPayload,
    private fb: FormBuilder,
    private gbrService: GbrService,
    private gbrDocumentService: GbrDocumentsService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private saveService: GbrPersonSaveService,
    private toastrService: ToastrService,
    private events: GlobalEventsService,
    private destroyRef: DestroyRef,
    private userService: GeneralUserService
  ) {}

  ngOnInit(): void {
    this.loadingCount++;

    this.setFormGroups();
    this.addEmptyControls();

    this.loadExistingTravelRequest();
    this.loadVacationCountries();

    this.loadingCount--;

    this.events
      .listen('beforeunload')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => this.unloadHandler(event));
  }

  private loadEmployeeData(): void {
    this.userService
      .getGbrEmployeeDetailsByGid(this.data.gid)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(employee => {
        this.employeeData = employee;
      });
  }

  private loadExistingTravelRequest(): Subscription {
    this.loadingCount++;

    return this.gbrService
      .getTravelRequestByGid(this.data.gid, this.data.seasonId)
      .pipe(
        filter(data => !!data),
        tap(data => {
          this.existingTravelRequest = data;
          if (this.data.representative) {
            this.existingTravelRequest.filledOutByRepresentativeUserId = this.data.representative;
            this.existingTravelRequest.isFilledOutByRepresentative = !!this.data.representative;
          }
        }),
        tap(data => {
          const gbrInput = GbrInput.fromTravelRequestDto(data);
          this.setFormValues(gbrInput);
          if (this.existingTravelRequest?.requestState === TravelRequestState.Submitted && !this.data.isAdmin) {
            this.travelPersons.disable();
          } else {
            this.addEmptyControls();
          }
          this.loadingCount--;
        }),
        catchError(err => {
          this.loadingCount--;
          return throwError(err);
        }),
        finalize(() => this.loadEmployeeData())
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private loadVacationCountries(): void {
    this.gbrService
      .getVacationCountryOptions()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(value => {
        this.countries = value;
      });
  }

  private checkForm(): boolean {
    this.travelPersons.markAllAsTouched();
    if (!this.isFormValid) {
      this.toastrService.error('Form contains errors');
    }
    return this.isFormValid;
  }

  public save(): void {
    if (this.checkForm()) {
      this.loadingCount++;
      this.createOrUpdateRequest()
        .pipe(
          filter(data => !!data),
          tap(data => this.setFormValues(data)),
          tap(() => {
            if (this.existingTravelRequest?.requestState === TravelRequestState.Submitted && !this.data.isAdmin) {
              this.travelPersons.disable();
            } else {
              this.addEmptyControls();
            }
          }),
          finalize(() => this.loadingCount--)
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe();
      this.resetFormState();
    }
  }

  public submit(): void {
    if (this.checkForm()) {
      this.loadingCount++;
      this.createOrUpdateRequest()
        .pipe(
          switchMap(() => this.gbrService.submitTravelRequest(this.existingTravelRequest.id, this.existingTravelRequest)),
          finalize(() => this.loadingCount--)
        )
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => this.close(true, true));
    }
  }

  private createOrUpdateRequest(): Observable<GbrInput> {
    this.saveService.emitSave();

    const input = this.getGbrInput();
    const dto = this.getTravelRequest(input);
    const request$ = dto.id ? this.gbrService.updateTravelRequest(dto) : this.gbrService.createTravelRequest(dto);
    return forkJoin(this.uploadFiles(input)).pipe(
      switchMap(() => request$),
      tap(result => {
        this.existingTravelRequest = result;
      }),
      map(result => GbrInput.fromTravelRequestDto(result))
    );
  }

  private uploadFiles(input: GbrInput): Observable<object>[] {
    const uploadFiles = input.files.filter(file => file?.file);
    if (!uploadFiles.length) {
      return [of(null)];
    }
    return uploadFiles.map(file => this.gbrDocumentService.uploadGbrDocument(file));
  }

  private getGbrInput(): GbrInput {
    const value = this.travelPersons.getRawValue();
    const inputDto = new GbrInput(value as Partial<GbrInput>);
    inputDto.addFilePath(this.data.gid, this.data.seasonId);
    return inputDto;
  }

  private getTravelRequest(input: GbrInput): GbrTravelRequestDto {
    const travelRequest = this.existingTravelRequest || this.getNewRequestBaseValues();
    const inputDto = input.getRequestDto();
    return {
      ...travelRequest,
      ...inputDto
    } as GbrTravelRequestDto;
  }

  private getNewRequestBaseValues(): Partial<GbrTravelRequestDto> {
    return {
      seasonId: this.data.seasonId,
      memberId: this.existingTravelRequest?.memberId ?? this.data.gbrSeasonInfo?.memberId,
      employeeId: this.existingTravelRequest?.employeeId ?? this.data.gbrSeasonInfo?.employeeId,
      hasAcknowledgedPrivacyData: true,
      privacyDataAcknowledgedAt: this.data.privacyCheckedDate,
      isFilledOutByRepresentative: !!this.data.representative,
      filledOutByRepresentativeUserId: this.data.representative || EmptyGuid
    };
  }

  private setFormGroups(): void {
    this.travelPersons = this.fb.group({
      personalInfos: this.fb.group({
        isSingleParent: false,
        isShiftWorker: false,
        email: '',
        hasParentLeave: false,
        hasDisabilities: false,
        hasCare: false
      }),
      vacation: this.fb.group({
        country: ['', Validators.required],
        period: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          start: '' as any,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          end: '' as any
        }
      }),
      spouse: [null],
      children: this.fb.array<GbrChildInput>([]),
      files: this.fb.array<GbrFile>([])
    });
    this.setValidationObservers();
  }

  private setValidationObservers(): void {
    const formValues$ = this.travelPersons.valueChanges.pipe(startWith(this.travelPersons.value));
    this.minOneFileAdded$ = formValues$.pipe(map(value => value.files?.length > 1));
    this.vacationPeriodIsFilled$ = formValues$.pipe(
      map(value => value.vacation.period.start && value.vacation.period.end && value.vacation.period.end.diff(value.vacation.period.start, 'days') >= 6)
    );
    combineLatest([this.minOneFileAdded$, this.vacationPeriodIsFilled$])
      .pipe(
        map(([files, vacationPeriod]) => !!files && !!vacationPeriod && !this.travelPersons.disabled && this.inputIsValid),
        tap(isFormValid => {
          this.isFormValid = isFormValid;
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private setFormValues(data: GbrInput): void {
    this.travelPersons.controls.personalInfos.setValue(data.personalInfos);
    this.travelPersons.controls.vacation.setValue(data.vacation);
    if (Object.keys(data.spouse).length) {
      this.travelPersons.controls.spouse.setValue(data.spouse);
    }

    const childrenForm = this.travelPersons.controls.children;
    childrenForm.clear();
    data.children.forEach(child => childrenForm.push(new FormControl(child)));

    const fileForm = this.travelPersons.controls.files;
    fileForm.clear();
    data.files.forEach(file => fileForm.push(new FormControl(file)));
  }

  private addEmptyControls(): void {
    this.addEmptyChild();
    this.addEmptyFile();
  }

  private addEmptyChild(): void {
    if (this.lastChildObserver) {
      this.lastChildObserver.unsubscribe();
    }
    const newChild = new FormControl(null);
    this.children.push(newChild);
    this.lastChildObserver = newChild.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.addEmptyChild());
  }

  private addEmptyFile(): void {
    if (this.lastFileObserver) {
      this.lastFileObserver.unsubscribe();
    }
    const newFile = new FormControl(null);
    this.files.push(newFile);
    this.lastFileObserver = newFile.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.addEmptyFile());
  }

  public removeSpouse(): void {
    this.spouse.setValue(null);
  }

  public removeChild(index: number): void {
    this.children.removeAt(index);
  }

  public deleteFile(index: number): void {
    this.files.removeAt(index);
  }

  public previous(): void {
    this.stepper.previous();
  }
  public next(): void {
    this.stepper.next();
  }

  private resetFormState(): void {
    this.travelPersons.markAsPristine();
    this.travelPersons.markAsUntouched();
  }

  async close(skipChangesCheck?: boolean, triggerReload?: boolean): Promise<void> {
    if (skipChangesCheck) {
      this.dialogRef.close(triggerReload);
      return;
    }

    const changes = await this.hasUnsavedChanges();

    if (!changes) {
      this.dialogRef.close(triggerReload);
    }
  }

  public delete(): void {
    if (!this.existingTravelRequest?.id) {
      return;
    }

    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          headline: 'general.confirm',
          translate: true,
          msg: `gbr.delete-confirm-text`,
          confirmMsg: 'gbr.delete',
          cancelMsg: 'general.btnCancel'
        }
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(result => !!result),
        tap(() => this.loadingCount++),
        switchMap(() => this.gbrService.deleteTravelRequest(this.existingTravelRequest.id)),
        finalize(() => this.loadingCount--)
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.close(true, true));
  }

  private async hasUnsavedChanges(): Promise<boolean> {
    if (!this.hasChanges) {
      return false;
    }

    const result = await this.dialog
      .open(UnsavedChangesDialogComponent, {
        data: {
          title: this.translate.instant('dialog.unsaved-changes.title')
        }
      })
      .afterClosed()
      .toPromise();

    if (!result) {
      return true;
    }

    if (result === 'save') {
      this.save();
    }

    return false;
  }
}
