import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { CardPadding, V2CardComponent } from '../card/v2-card.component';
import { V2CheckboxComponent } from '../checkbox/v2-checkbox.component';

@Component({
  selector: 'coin-v2-selectable-card',
  templateUrl: './v2-selectable-card.component.html',
  styleUrls: ['./v2-selectable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [V2CardComponent, V2CheckboxComponent, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2SelectableCardComponent),
      multi: true
    }
  ]
})
export class V2SelectableCardComponent extends DefaultControlValueAccessor<boolean> {
  @Input() padding: CardPadding = 'medium';

  @HostBinding('class')
  private get class(): string {
    return `${this.disabled ? 'disabled' : ''}`;
  }

  protected toggleCard(): void {
    if (this.disabled) return;
    this.value = !this.value;
  }
}
