import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  contentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  Signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { TemplateWithValueDirective } from '@coin/shared/util-directives';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';
import { TranslateModule } from '@ngx-translate/core';
import { InputSize, MatFormFieldClass } from '../../input.types';
import { InputDebounceTime, V2TextInputComponent } from '../text-input/v2-text-input.component';

@Component({
  selector: 'coin-v2-dropdown',
  templateUrl: './v2-dropdown.component.html',
  styleUrls: ['./v2-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, FormsModule, V2TextInputComponent, MatSelectModule, MatInputModule, TranslateModule, ReactiveFormsModule, MatIconModule, MatTooltip],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2DropdownComponent),
      multi: true
    }
  ]
})
export class V2DropdownComponent<T = unknown> extends DefaultControlValueAccessor<T> implements AfterViewInit {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() matFormFieldClass: MatFormFieldClass = 'customer-grey';
  @Input() multiple = false;
  @Input() loading: boolean;
  @Input() size: InputSize = 'medium';
  @Input() searchDebounce: InputDebounceTime = 300;
  @Input() compareWith: (o1: T, o2: T) => boolean = (o1, o2) => o1 === o2;
  @Output() search = new EventEmitter<string>();
  @Output() openedChange = new EventEmitter<boolean>();
  @ContentChild('selectedValueTemplate') selectedValueTemplate: TemplateRef<T>;
  options: Signal<readonly TemplateWithValueDirective<T>[]> = contentChildren(TemplateWithValueDirective);
  @ViewChild(V2TextInputComponent) searchInput: V2TextInputComponent;
  @ViewChild(MatSelect) matSelect: MatSelect;

  protected searchControl = new FormControl('');

  @HostBinding('attr.aria-hidden')
  get hasNoOptions() {
    return !this.options?.length ? true : null;
  }

  @HostBinding('class')
  private get class(): string {
    return `size-${this.size}`;
  }

  protected get selectTriggerType() {
    if (!this.value || (Array.isArray(this.value) && !this.value[0])) return null;

    if (Array.isArray(this.value) && this.value.length > 1) {
      return 'multiple-values';
    }
    if (this.selectedValueTemplate && (!Array.isArray(this.value) || this.value.length === 1)) {
      return 'template';
    }

    return null; // default display
  }

  constructor(private destroy: DestroyRef) {
    super();
  }

  public ngAfterViewInit(): void {
    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroy)).subscribe(value => this.search.emit(value));
  }

  protected onOpenedChange(opened: boolean): void {
    if (opened) {
      this.focusSearchInputIfPresent();
    }
    this.openedChange.emit(opened);
  }

  private focusSearchInputIfPresent(): void {
    this.searchInput?.focus();
  }

  public resetSearch(): void {
    this.searchControl.reset('');
  }

  public openDropdown(): void {
    this.matSelect.open();
  }
}
