import { Directive, input, Input, OnInit, TemplateRef } from '@angular/core';

@Directive({
  selector: '[coinTab]',
  exportAs: 'v2Tab',
  standalone: false
})
export class V2TabDirective implements OnInit {
  @Input({ required: true, alias: 'coinTab' }) content: string | TemplateRef<unknown>;
  @Input() icon: string;
  @Input() isDisabled = false;
  @Input() disabledTooltip = '';

  /** If set to true, tab content will only load when selected */
  @Input() lazy = false;
  /** If set to true, lazy tabs will remain loaded after being unselected */
  @Input() cache = false;

  @Input() route: string;

  constructor(public template: TemplateRef<unknown>) {}

  public ngOnInit(): void {
    if (this.cache && !this.lazy) throw new Error('Caching tabs requires them to be lazy');
    if (this.route && this.lazy) throw new Error('Tabs cannot be both routed and lazy');
  }

  public matchesUrl(url: string): boolean {
    if (!this.route) return false;
    return url.split('?')[0] === `/${this.route}`;
  }
}
